import * as types from "../types";

export const init = {
  reviews: [],
  count: null,
  current: 0,
  pages: null,
  loading: false,
  author: null,
  order: "mostRating,-createdAt",
};

export default function reviews(state = {}, action) {
  switch (action.type) {
    case types.FETCH_REVIEWS:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_REVIEWS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.replace ? action.data.results : [...state.reviews, ...action.data.results],
        pages: action.data.pages,
        count: action.data.count,
        current: action.data.current,
        averageRating: action.data.avg_rating,
        order: action.order || state.order,
        loading: false,
      };
    case types.FETCH_AUTHOR_REVIEWS:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_AUTHOR_REVIEWS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_AUTHOR_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.replace ? action.data.results : [...state.reviews, ...action.data.results],
        pages: action.data.pages,
        count: action.data.count,
        current: action.data.current,
        averageRating: action.data.avg_rating,
        order: action.order || state.order,
        loading: false,
      };
    case types.NORMALIZE_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.data,
        pages: Math.ceil(state.count / action.perPage),
        current: Math.floor(action.data.length / action.perPage),
      };
    default:
      return state;
  }
}
