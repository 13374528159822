/* eslint-disable camelcase */
import { logo } from "./logo";
import { bag } from "./bag";
import { pencil } from "./pencil";
import { exit } from "./exit";
import { lang } from "./lang";
import { chevron } from "./chevron";
import { group } from "./group";
import { notFound } from "./notFound";
import { star } from "./star";
import { car } from "./car";
import { clock } from "./clock";
import { check } from "./check";
import { cookies } from "./cookies";
import { charge } from "./charge";
import { cross } from "./cross";
import { event } from "./event";
import { categories } from "./categories";
import { face } from "./face";
import { sort } from "./sort";
import { facebook } from "./social/facebook";
import { twitter } from "./social/twitter";
import { instagram } from "./social/instagram";
import { pinterest } from "./social/pinterest";
import { planet } from "./planet";
import { building } from "./building";
import { tiktok } from "./social/tiktok";
import { linkedin } from "./social/linkedin";
import { mail } from "./mail";
import { close } from "./close";
import { copy } from "./copy";
import { filter } from "./filter";
import { surfing } from "./surfing";
import { mobile } from "./mobile";
import { head } from "./head";
import { cloud_download } from "./cloud-download";
import { download } from "./download";
import { reviews } from "./reviews";
import { list } from "./list";
import { skipLine } from "./skip-line";
import { headphones } from "./headphones";
import { languageContent } from "./language-content";
import { location } from "./location";
import { lock } from "./lock";
import { key } from "./key";
import { shoppingCart } from "./shopping-cart";
import { audioTicket } from "./audio-ticket";
import { done } from "./done";
import { refresh } from "./refresh";
import { loading } from "./loading";
import { pause } from "./pause";
import { play } from "./play";
import { pin } from "./pin";
import { pay } from "./pay";
import { preorder } from "./preorder";
import { picture } from "./picture";
import { aitour } from "./aitour";
import { search } from "./search";
import { chat } from "./chat";
import { tick } from "./tick";
import { arrow } from "./arrow";
import { flag } from "./flag";
import { bookmark } from "./bookmark";
import { house } from "./house";
import { person } from "./person";
import { currency } from "./currency";
import { help } from "./help";
import { heart } from "./heart";
import { productType } from "./product-type";
import { details } from "./details";
import { hand } from "./hand";
import { participiants } from "./participiants";
import { price } from "./price";
import { bookingNumber } from "./booking-number";
import { calendar } from "./calendar";
import { globeLanguage } from "./globe-language";
import { handPhone } from "./hand-phone";
import { controlCheck } from "./control-check";
import { martian } from "./martian";
import { foundFlag } from "./found-flag";
import { language } from "./language";
import { share } from "./share";
import { mobileScreenShare } from "./mobile-screen-share";
import { mailSubscriptionDone } from "./mail-subscriptionDone";
import { mailSubscription } from "./mail-subscription";
import { computer } from "./computer";
import { shield } from "./shield";
import { link } from "./link";
import { email } from "./email";
import { step } from "./step";
import { ticket } from "./ticket";
import { timer } from "./timer";
import { tiqets } from "./tiqets";
import { loginBenefits } from "./login-benefits";
import { product } from "./product";
import { whatsapp } from "./whatsapp";
import { wifi } from "./wifi";
import { newline } from "./newline";
import { vk } from "./social/vk";
import { route } from "./route";

/**
 * Available shapes of the icons
 * @type {Object.<String, IconShape>}
 */
export const shapes = {
  ...logo,
  ...bag,
  ...product,
  ...pencil,
  ...exit,
  ...lang,
  ...chevron,
  ...group,
  ...star,
  ...check,
  ...cross,
  ...currency,
  ...facebook,
  ...twitter,
  ...instagram,
  ...pinterest,
  ...tiktok,
  ...linkedin,
  ...planet,
  ...building,
  ...car,
  ...charge,
  ...cookies,
  ...face,
  ...clock,
  ...close,
  ...copy,
  ...flag,
  ...bookmark,
  ...house,
  ...skipLine,
  ...head,
  ...cloud_download,
  ...download,
  ...headphones,
  ...languageContent,
  ...reviews,
  ...location,
  ...mobileScreenShare,
  ...computer,
  ...filter,
  ...shoppingCart,
  ...lock,
  ...key,
  ...play,
  ...preorder,
  ...aitour,
  ...pause,
  ...surfing,
  ...audioTicket,
  ...language,
  ...mobile,
  ...picture,
  ...pin,
  ...pay,
  ...search,
  ...step,
  ...tick,
  ...timer,
  ...chat,
  ...categories,
  ...arrow,
  ...list,
  ...productType,
  ...details,
  ...person,
  ...help,
  ...event,
  ...heart,
  ...refresh,
  ...hand,
  ...participiants,
  ...price,
  ...bookingNumber,
  ...calendar,
  ...globeLanguage,
  ...handPhone,
  ...controlCheck,
  ...martian,
  ...share,
  ...foundFlag,
  ...shield,
  ...link,
  ...email,
  ...ticket,
  ...sort,
  ...tiqets,
  ...loginBenefits,
  ...mail,
  ...mailSubscriptionDone,
  ...mailSubscription,
  ...whatsapp,
  ...done,
  ...loading,
  ...wifi,
  ...newline,
  ...vk,
  ...route,
  notFound,
};
