/* eslint-disable */ module.exports = {
  languageData: {
    plurals: function (n, ord) {
      var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2);
      if (ord)
        return n10 == 1 && n100 != 11
          ? "one"
          : n10 == 2 && n100 != 12
          ? "two"
          : n10 == 3 && n100 != 13
          ? "few"
          : "other";
      return n == 1 && v0 ? "one" : "other";
    },
  },
  messages: {
    "(optional)": "(optional)",
    "+ 44 208 089 2265": "+ 44 208 089 2265",
    "+ 44 208 089 69 25": "+ 44 208 089 69 25",
    "+1 650 989 9795": "+1 650 989 9795",
    "+44 20 80896925": "+44 20 80896925",
    "+44 7514 038891": "+44 7514 038891",
    "...More": "...More",
    "1-3 hours": "1-3 hours",
    "100+ self-guided tours": "100+ self-guided tours",
    "3 business days": "3 business days",
    "3-D secure auth failed.": "3-D secure auth failed.",
    "4 hours - 1 day": "4 hours - 1 day",
    "48 hours": "48 hours",
    "5% discount": "5% discount",
    "<0>Send again</0>": "<0>Send again</0>",
    "A Stripe test card number was used.": "A Stripe test card number was used.",
    "A bank processing error occured. Please, try again.":
      "A bank processing error occured. Please, try again.",
    "A bank processing error occurred. Please try again.":
      "A bank processing error occurred. Please try again.",
    "A bank processing error occurred. Please, try again.":
      "A bank processing error occurred. Please, try again.",
    "A transaction with identical amount and credit card information was submitted very recently. Please check the previous order or try again later.":
      "A transaction with identical amount and credit card information was submitted very recently. Please check the previous order or try again later.",
    "AI generated tour": "AI generated tour",
    About: "About",
    "About host, Author{user_id}": function (a) {
      return ["About host, Author", a("user_id")];
    },
    "About host, {name}": function (a) {
      return ["About host, ", a("name")];
    },
    "About in-app audio tours": "About in-app audio tours",
    "About this activity": "About this activity",
    "About you": "About you",
    "About {0}": function (a) {
      return ["About ", a("0")];
    },
    "About {toString}": function (a) {
      return ["About ", a("toString")];
    },
    Accept: "Accept",
    "Access to an audio tour for one person on iPhone or Android":
      "Access to an audio tour for one person on iPhone or Android",
    Account: "Account",
    Actions: "Actions",
    "Activities found:": "Activities found:",
    "Add this code snippet to the part of the page where you want the widget to appear":
      "Add this code snippet to the part of the page where you want the widget to appear",
    "Add to favorites": "Add to favorites",
    "Add tours to your wishlist by clicking on heart button":
      "Add tours to your wishlist by clicking on heart button",
    "Added to favorites": "Added to favorites",
    "Additional Info": "Additional Info",
    "Affiliate Account": "Affiliate Account",
    "Affiliate program": "Affiliate program",
    "After purchase, you will receive the link to download the app to take this tour":
      "After purchase, you will receive the link to download the app to take this tour",
    "After purchase, you will receive the link to download the app to take this tour.":
      "After purchase, you will receive the link to download the app to take this tour.",
    "All audio tours and tickets can be downloaded to your device anytime":
      "All audio tours and tickets can be downloaded to your device anytime",
    "All audio tours and tickets can be downloaded to your device anytime.":
      "All audio tours and tickets can be downloaded to your device anytime.",
    "All audio tours are tested for high quality and easy navigation":
      "All audio tours are tested for high quality and easy navigation",
    "All cities in {0}": function (a) {
      return ["All cities in ", a("0")];
    },
    "All destinations": "All destinations",
    "All participants are invited": "All participants are invited",
    "All photos": "All photos",
    "All tours and tickets by {0}": function (a) {
      return ["All tours and tickets by ", a("0")];
    },
    "All tours and tickets by {authorName}": function (a) {
      return ["All tours and tickets by ", a("authorName")];
    },
    "All tours and tickets in {0}": function (a) {
      return ["All tours and tickets in ", a("0")];
    },
    "All tours and tickets in {cityName}": function (a) {
      return ["All tours and tickets in ", a("cityName")];
    },
    "All tours and tickets in {countryName}": function (a) {
      return ["All tours and tickets in ", a("countryName")];
    },
    "Already have an account?": "Already have an account?",
    "Also we\u2019ll send this code via Whatsapp and to the email.":
      "Also we\u2019ll send this code via Whatsapp and to the email.",
    "An honest exchange of opinions builds trust and improves the product. That's why we ask for feedback":
      "An honest exchange of opinions builds trust and improves the product. That's why we ask for feedback",
    "An unexpected error has occurred.": "An unexpected error has occurred.",
    "An unexpected error has occurred. Please try another card or contact your bank.":
      "An unexpected error has occurred. Please try another card or contact your bank.",
    "An unexpected error occurred.": "An unexpected error occurred.",
    "An unexpected error occurred. Please try again later.":
      "An unexpected error occurred. Please try again later.",
    "An unexpected error occurred. Please try again.":
      "An unexpected error occurred. Please try again.",
    Analytics: "Analytics",
    "Any additional requirements for the route": "Any additional requirements for the route",
    "Any day": "Any day",
    "Any questions left?": "Any questions left?",
    Appearance: "Appearance",
    Apply: "Apply",
    Arabic: "Arabic",
    Articles: "Articles",
    "As promised, we give a promo code with a {discountOption} on the first purchase": function (
      a,
    ) {
      return [
        "As promised, we give a promo code with a ",
        a("discountOption"),
        " on the first purchase",
      ];
    },
    "Attractions in {0}": function (a) {
      return ["Attractions in ", a("0")];
    },
    "Attractions in {cityName}": function (a) {
      return ["Attractions in ", a("cityName")];
    },
    "Attractions on the route": "Attractions on the route",
    Audio: "Audio",
    "Audio Tour": "Audio Tour",
    "Audio Tour could not be prepared, please change or cancel your order":
      "Audio Tour could not be prepared, please change or cancel your order",
    "Audio Tour in, {0}": function (a) {
      return ["Audio Tour in, ", a("0")];
    },
    "Audio Tour in, {0}, {1}": function (a) {
      return ["Audio Tour in, ", a("0"), ", ", a("1")];
    },
    "Audio guide for {cityName} - Download and listen to audio tours for {cityName}": function (a) {
      return [
        "Audio guide for ",
        a("cityName"),
        " - Download and listen to audio tours for ",
        a("cityName"),
      ];
    },
    "Audio guide price": "Audio guide price",
    "Audio guides for {cityName} - Download and listen to audio tours for {cityName}": function (
      a,
    ) {
      return [
        "Audio guides for ",
        a("cityName"),
        " - Download and listen to audio tours for ",
        a("cityName"),
      ];
    },
    "Audio guides in {cityName} - Download and listen to self-guided audio tours": function (a) {
      return [
        "Audio guides in ",
        a("cityName"),
        " - Download and listen to self-guided audio tours",
      ];
    },
    "Audio tour": "Audio tour",
    "Audio tour - available for download": "Audio tour - available for download",
    "Audio tour - is being prepared, will be available no later than":
      "Audio tour - is being prepared, will be available no later than",
    "Audio tour and entrance": "Audio tour and entrance",
    "Audio tour and entrance ticket": "Audio tour and entrance ticket",
    "Audio tours on your smartphone": "Audio tours on your smartphone",
    "Authentication failed. Please try again or contact your bank.":
      "Authentication failed. Please try again or contact your bank.",
    "Authentication failed. Please try again.": "Authentication failed. Please try again.",
    "Authentication failed. Please try again. An unexpected error has occurred.":
      "Authentication failed. Please try again. An unexpected error has occurred.",
    "Authentication failed. Please, try again.": "Authentication failed. Please, try again.",
    "Authentication was required. Please try again.":
      "Authentication was required. Please try again.",
    Author: "Author",
    "Author's answer ({0})": function (a) {
      return ["Author's answer (", a("0"), ")"];
    },
    "Available number of tickets: {totalCapacity}": function (a) {
      return ["Available number of tickets: ", a("totalCapacity")];
    },
    "Available number of tickets: {totalCapacity}>>>>>>> 387f9b856a20fcc897a88e9ea3eb26ed1cd4ea4f":
      function (a) {
        return [
          "Available number of tickets: ",
          a("totalCapacity"),
          ">>>>>>> 387f9b856a20fcc897a88e9ea3eb26ed1cd4ea4f",
        ];
      },
    Average: "Average",
    Back: "Back",
    "Become a supplier": "Become a supplier",
    "Become an affiliate partner": "Become an affiliate partner",
    "Become an author": "Become an author",
    "Best cities to visit in {0}": function (a) {
      return ["Best cities to visit in ", a("0")];
    },
    "Best cities to visit in {countryName}": function (a) {
      return ["Best cities to visit in ", a("countryName")];
    },
    "Best cities to visit in {locationName}": function (a) {
      return ["Best cities to visit in ", a("locationName")];
    },
    "Best cities to visit in {location}": function (a) {
      return ["Best cities to visit in ", a("location")];
    },
    Bestseller: "Bestseller",
    "Book a tour": "Book a tour",
    "Book now": "Book now",
    "Book order": "Book order",
    "Book other activities for your next trip within 90 days in the WeGoTrip app and get 10% off":
      "Book other activities for your next trip within 90 days in the WeGoTrip app and get 10% off",
    "Book other activities for your next trip within 90 days in the WeGoTrip app and get 10% off.":
      "Book other activities for your next trip within 90 days in the WeGoTrip app and get 10% off.",
    "Book self-guided audio tours with skip-the-line tickets to museums, galleries, and many other attractions":
      "Book self-guided audio tours with skip-the-line tickets to museums, galleries, and many other attractions",
    "Book self-guided audio tours with skip-the-line tickets to museums, galleries, and many other attractions.":
      "Book self-guided audio tours with skip-the-line tickets to museums, galleries, and many other attractions.",
    "Book with": "Book with",
    "Book with confidence": "Book with confidence",
    "Booked by: {0}": function (a) {
      return ["Booked by: ", a("0")];
    },
    "Booked by: {customer_name}": function (a) {
      return ["Booked by: ", a("customer_name")];
    },
    "Booking details": "Booking details",
    "Booking number": "Booking number",
    "Booking number: {code}": function (a) {
      return ["Booking number: ", a("code")];
    },
    "Book\xA0now": "Book\xA0now",
    "Bring headphones for easier listening": "Bring headphones for easier listening",
    "British Pound": "British Pound",
    Bulgarian: "Bulgarian",
    "Buy tickets online to {attractionName} in {0} \u2606 Prices from {1} \u2606 Book your tickets, download and listen to the audio guide. Join thousands happy customers who use self-guided tours in the App":
      function (a) {
        return [
          "Buy tickets online to ",
          a("attractionName"),
          " in ",
          a("0"),
          " \u2606 Prices from ",
          a("1"),
          " \u2606 Book your tickets, download and listen to the audio guide. Join thousands happy customers who use self-guided tours in the App",
        ];
      },
    "Buy tickets online to {attractionName} in {0}. Online ticket prices. Order e-tickets to {attractionName}.":
      function (a) {
        return [
          "Buy tickets online to ",
          a("attractionName"),
          " in ",
          a("0"),
          ". Online ticket prices. Order e-tickets to ",
          a("attractionName"),
          ".",
        ];
      },
    "By continuing, you agree to WeGoTrip": "By continuing, you agree to WeGoTrip",
    "By signing in, you agree to <0>Terms & Conditions</0> and <1>Privacy Policy</1>.":
      "By signing in, you agree to <0>Terms & Conditions</0> and <1>Privacy Policy</1>.",
    "By signing in, you agree to <0>Terms & Conditions</0>and <1>Privacy Policy</1>.":
      "By signing in, you agree to <0>Terms & Conditions</0> and <1>Privacy Policy</1>.",
    "By signing up, you agree to receive promotional emails on activities and insider tips. You can unsubscribe or withdraw your consent at any time with future effect. For more information, read our":
      "By signing up, you agree to receive promotional emails on activities and insider tips. You can unsubscribe or withdraw your consent at any time with future effect. For more information, read our",
    "By signing up, you agree to receive promotional emails on activities and insider tips. You can unsubscribe or withdraw your consent at any time with future effect. For more information, read our Privacy statement.":
      "By signing up, you agree to receive promotional emails on activities and insider tips. You can unsubscribe or withdraw your consent at any time with future effect. For more information, read our Privacy statement.",
    "CVC/CVV": "CVC/CVV",
    "Cancellation policies vary according to activity type. Please":
      "Cancellation policies vary according to activity type. Please",
    "Cancellation policy": "Cancellation policy",
    "Can\u2019t find a tour you are looking for? Ask for On Demand one!":
      "Can\u2019t find a tour you are looking for? Ask for On Demand one!",
    "Car tour": "Car tour",
    "Card expired": "Card expired",
    "Card information": "Card information",
    "Card is restricted.": "Card is restricted.",
    "Card issued in this country is not accepted.": "Card issued in this country is not accepted.",
    "Card number": "Card number",
    "Cardholder name": "Cardholder name",
    Careers: "Careers",
    Categorie: "Categorie",
    Categories: "Categories",
    "Categories in {cityName}, {0}": function (a) {
      return ["Categories in ", a("cityName"), ", ", a("0")];
    },
    "Categories in {cityName}, {countryName}": function (a) {
      return ["Categories in ", a("cityName"), ", ", a("countryName")];
    },
    Category: "Category",
    "Change or Cancel": "Change or Cancel",
    "Change the time and date for free": "Change the time and date for free",
    "Charge your phone": "Charge your phone",
    "Chat, phone or email: we are always here to help without endless holds.":
      "Chat, phone or email: we are always here to help without endless holds.",
    Check: "Check",
    "Check availability": "Check availability",
    "Check dates": "Check dates",
    "Check out these {0} other activities nearby {1}": function (a) {
      return ["Check out these ", a("0"), " other activities nearby ", a("1")];
    },
    "Check out these {0} other activities nearby {cityNameNearbySubtitle}": function (a) {
      return ["Check out these ", a("0"), " other activities nearby ", a("cityNameNearbySubtitle")];
    },
    "Check out these {0} other activities nearby {cityName}": function (a) {
      return ["Check out these ", a("0"), " other activities nearby ", a("cityName")];
    },
    "Check the latest tours generated On Demand": "Check the latest tours generated On Demand",
    "Check this self-guided tour in {cityName}": function (a) {
      return ["Check this self-guided tour in ", a("cityName")];
    },
    "Check your email": "Check your email",
    "Checking...": "Checking...",
    "Checkout for": "Checkout for",
    "Checkout is fast and secure": "Checkout is fast and secure",
    Chinese: "Chinese",
    "Choose a city or attraction": "Choose a city or attraction",
    "Choose a time slot": "Choose a time slot",
    "Choose another day": "Choose another day",
    "Choose high-quality audio tours created by certified guides.":
      "Choose high-quality audio tours created by certified guides.",
    "Choose high-quality audio tours in your native language.":
      "Choose high-quality audio tours in your native language.",
    "Choose participants": "Choose participants",
    "Cities in {countryName}": function (a) {
      return ["Cities in ", a("countryName")];
    },
    City: "City",
    "City Duration, h": "City Duration, h",
    "City or Attraction": "City or Attraction",
    "City, attraction, museum, tour name...": "City, attraction, museum, tour name...",
    "City, attraction, tour name...": "City, attraction, tour name...",
    Clear: "Clear",
    "Click here for a full list": "Click here for a full list",
    Close: "Close",
    Closed: "Closed",
    "Closed now:": "Closed now:",
    "Common questions": "Common questions",
    Company: "Company",
    Confirm: "Confirm",
    "Confirm details": "Confirm details",
    "Confirm order": "Confirm order",
    "Confirm your number": "Confirm your number",
    "Congratulations on your purchase, your choice is the best!":
      "Congratulations on your purchase, your choice is the best!",
    "Congratulations!": "Congratulations!",
    "Connecting...": "Connecting...",
    "Contact Support": "Contact Support",
    "Contact WeGoTrip": "Contact WeGoTrip",
    "Contact us": "Contact us",
    "Contact us \u2192": "Contact us \u2192",
    Contacts: "Contacts",
    Continue: "Continue",
    "Continue searching in {0}": function (a) {
      return ["Continue searching in ", a("0")];
    },
    "Continue searching in {cityName}": function (a) {
      return ["Continue searching in ", a("cityName")];
    },
    "Continue with Email": "Continue with Email",
    "Continue with password": "Continue with password",
    "Continue with {social}": function (a) {
      return ["Continue with ", a("social")];
    },
    "Control your experience": "Control your experience",
    "Cookies!": "Cookies!",
    Copied: "Copied",
    "Copied!": "Copied!",
    Copy: "Copy",
    "Copy link": "Copy link",
    "Copy to clipboard": "Copy to clipboard",
    "Could not fetch available dates. Please, refresh this page or select another tour.":
      "Could not fetch available dates. Please, refresh this page or select another tour.",
    "Could not fetch available options. Please, refresh this page or select another tour.":
      "Could not fetch available options. Please, refresh this page or select another tour.",
    "Could not fetch timeslots. Please, refresh this page or select another date.":
      "Could not fetch timeslots. Please, refresh this page or select another date.",
    Count: "Count",
    Country: "Country",
    Create: "Create",
    "Create a tour widget": "Create a tour widget",
    "Create an account to save your favorite tours add access your orders from any device":
      "Create an account to save your favorite tours add access your orders from any device",
    "Create an order": "Create an order",
    "Credit card": "Credit card",
    "Credit or debit card": "Credit or debit card",
    "Customer Care in English": "Customer Care in English",
    "Customer support": "Customer support",
    Czech: "Czech",
    Danish: "Danish",
    Date: "Date",
    "Date and time": "Date and time",
    "Date: {formatedDate}": function (a) {
      return ["Date: ", a("formatedDate")];
    },
    Dates: "Dates",
    "Day off": "Day off",
    "Definitely recommend": "Definitely recommend",
    "Departure and return": "Departure and return",
    "Describe your experience": "Describe your experience",
    "Describe your resource (max 300 characters)": "Describe your resource (max 300 characters)",
    Destinations: "Destinations",
    "Detailed route from stop to stop with live GPS map on your smartphone":
      "Detailed route from stop to stop with live GPS map on your smartphone",
    Deutsch: "Deutsch",
    "Did not use the WeGoTrip service, it's mistake":
      "Did not use the WeGoTrip service, it's mistake",
    "Didn't like the tour? Get a refund!": "Didn't like the tour? Get a refund!",
    "Did\u2019t get a text.": "Did\u2019t get a text.",
    "Discount code": "Discount code",
    "Discover the best audio tours and tickets by {0}. Compare tour, read reviews, and instantly book tours with tickets.":
      function (a) {
        return [
          "Discover the best audio tours and tickets by ",
          a("0"),
          ". Compare tour, read reviews, and instantly book tours with tickets.",
        ];
      },
    "Discovering all destinations | WeGoTrip": "Discovering all destinations | WeGoTrip",
    "Discovering all destivations | WeGoTrip": "Discovering all destivations | WeGoTrip",
    'Discovering all destivations | WeGoTrip"': 'Discovering all destivations | WeGoTrip"',
    "Do I have to choose a timeslot even if I don't want to?":
      "Do I have to choose a timeslot even if I don't want to?",
    "Do I have to print tickets included in the tour?":
      "Do I have to print tickets included in the tour?",
    "Don't like it? We will return the money!": "Don't like it? We will return the money!",
    "Don't wait for the guide to start the tour. The tour and ticket are always at hand.":
      "Don't wait for the guide to start the tour. The tour and ticket are always at hand.",
    "Don't worry, please contact our support team to resolve your issue":
      "Don't worry, please contact our support team to resolve your issue",
    "Don't worry, we will send you a push once we got it":
      "Don't worry, we will send you a push once we got it",
    "Don\u2019t be depended on a guide or a group": "Don\u2019t be depended on a guide or a group",
    "Don\u2019t have an account?": "Don\u2019t have an account?",
    "Download WeGoTrip": "Download WeGoTrip",
    "Download WeGoTrip to access your activity in the WeGoTrip app":
      "Download WeGoTrip to access your activity in the WeGoTrip app",
    "Download for free on iOS and Android": "Download for free on iOS and Android",
    "Download the tickets and use the app even without the internet. Plus, you won't miss out on any special offers.":
      "Download the tickets and use the app even without the internet. Plus, you won't miss out on any special offers.",
    "Download tour": "Download tour",
    "Downloadable multimedia audio tour on WeGoTrip app":
      "Downloadable multimedia audio tour on WeGoTrip app",
    "Dura {duration}": function (a) {
      return ["Dura ", a("duration")];
    },
    Duration: "Duration",
    "Duration {duration}": function (a) {
      return ["Duration ", a("duration")];
    },
    "Duration: {duration}": function (a) {
      return ["Duration: ", a("duration")];
    },
    Dutch: "Dutch",
    "E-mail": "E-mail",
    "Earphones \u2014 please bring your own": "Earphones \u2014 please bring your own",
    "Edit booking": "Edit booking",
    Email: "Email",
    "Email to us": "Email to us",
    "Email:": "Email:",
    "Emails are lost among inbox": "Emails are lost among inbox",
    "Emails get into Spam": "Emails get into Spam",
    English: "English",
    "Enjoy self-guided audio tours with included attraction tickets in one app":
      "Enjoy self-guided audio tours with included attraction tickets in one app",
    "Enjoy the tour even offline": "Enjoy the tour even offline",
    "Enter the data of the participant": "Enter the data of the participant",
    Entrance: "Entrance",
    "Entrance ticket": "Entrance ticket",
    "Entrance tickets": "Entrance tickets",
    "Entry ticket": "Entry ticket",
    Error: "Error",
    "Error while getting timeslots. Please refresh the page or select a different date":
      "Error while getting timeslots. Please refresh the page or select a different date",
    Estonian: "Estonian",
    Euro: "Euro",
    Examples: "Examples",
    Excellent: "Excellent",
    "Expiry date": "Expiry date",
    "Explore all destinations": "Explore all destinations",
    FAQ: "FAQ",
    "FAQ about the {0}": function (a) {
      return ["FAQ about the ", a("0")];
    },
    "FAQ about {0}": function (a) {
      return ["FAQ about ", a("0")];
    },
    Facebook: "Facebook",
    "Failed to access YooKassa payment system.": "Failed to access YooKassa payment system.",
    "Failed to get available dates. Please reload the page or choose another product.":
      "Failed to get available dates. Please reload the page or choose another product.",
    Favorites: "Favorites",
    "Favorites | WeGoTrip": "Favorites | WeGoTrip",
    Filters: "Filters",
    Finnish: "Finnish",
    "First name": "First name",
    "Fixed price for a group of 5 people": "Fixed price for a group of 5 people",
    "For Investors": "For Investors",
    "For any questions, contact WeGoTrip": "For any questions, contact WeGoTrip",
    "For example, 6 out of 18 steps.": "For example, 6 out of 18 steps.",
    "For museums and attractions": "For museums and attractions",
    "Fraud suspected.": "Fraud suspected.",
    Free: "Free",
    "Free self-guided tours in cities of Russia created by locals":
      "Free self-guided tours in cities of Russia created by locals",
    French: "French",
    "Frequently bought together": "Frequently bought together",
    Friday: "Friday",
    "Friendly support": "Friendly support",
    From: "From",
    "Full description": "Full description",
    "Full name": "Full name",
    General: "General",
    "Generate your own link": "Generate your own link",
    "Generate your own link for tour": "Generate your own link for tour",
    "Generated links": "Generated links",
    German: "German",
    "Get a discount": "Get a discount",
    "Get free": "Get free",
    "Get free audio tours, travel Spotify playlists, and other goodies in your email.":
      "Get free audio tours, travel Spotify playlists, and other goodies in your email.",
    "Get in the app": "Get in the app",
    "Get now": "Get now",
    "Get ready for your next trip with 10% off for any activities":
      "Get ready for your next trip with 10% off for any activities",
    "Get ticket in the app": "Get ticket in the app",
    "Go on tour whenever you want and explore the sights as long as you like":
      "Go on tour whenever you want and explore the sights as long as you like",
    "Go to my tours": "Go to my tours",
    "Go to next step": "Go to next step",
    "Go to order": "Go to order",
    "Going to {0}?": function (a) {
      return ["Going to ", a("0"), "?"];
    },
    "Going to {0}? Look at our recommendations": function (a) {
      return ["Going to ", a("0"), "? Look at our recommendations"];
    },
    "Going to {cityName}? Look at our recommendations": function (a) {
      return ["Going to ", a("cityName"), "? Look at our recommendations"];
    },
    Good: "Good",
    "Google Pay": "Google Pay",
    "Google Pay is not available on this device.": "Google Pay is not available on this device.",
    Greek: "Greek",
    "Group from 1-5": "Group from 1-5",
    "Guests: {0}": function (a) {
      return ["Guests: ", a("0")];
    },
    "Guests: {categories}": function (a) {
      return ["Guests: ", a("categories")];
    },
    "Have a discount code?": "Have a discount code?",
    "Hello!": "Hello!",
    Help: "Help",
    "Help Center": "Help Center",
    "Help a fellow travelers in under a minute by reviewing your recent activity":
      "Help a fellow travelers in under a minute by reviewing your recent activity",
    "Hey there, I found a great self-guided tour in {cityName}: {0} Check it out: {currentUrl}":
      function (a) {
        return [
          "Hey there, I found a great self-guided tour in ",
          a("cityName"),
          ": ",
          a("0"),
          " Check it out: ",
          a("currentUrl"),
        ];
      },
    "Hi, {0}": function (a) {
      return ["Hi, ", a("0")];
    },
    Highlights: "Highlights",
    "Highly rated activities according to the travelers reviews":
      "Highly rated activities according to the travelers reviews",
    Hindi: "Hindi",
    Home: "Home",
    "Hosted by {name}": function (a) {
      return ["Hosted by ", a("name")];
    },
    "Hosted {variantActivities}": function (a) {
      return ["Hosted ", a("variantActivities")];
    },
    "Hosted {variantActivities\u0435}": function (a) {
      return ["Hosted ", a("variantActivities\u0435")];
    },
    "How does the refund work?": "How does the refund work?",
    "How is the tour going?": "How is the tour going?",
    "How to invite other guests to activity?": "How to invite other guests to activity?",
    "How was your activity?": "How was your activity?",
    Hungarian: "Hungarian",
    "I am going with my friend or family. Do I need to buy a tour for every person in the group?":
      "I am going with my friend or family. Do I need to buy a tour for every person in the group?",
    "I'm not going to use the WeGoTrip service anymore":
      "I'm not going to use the WeGoTrip service anymore",
    "If a particular date is shown as unavailable, this is either because {0} is closed or because there are no more tickets to purchase for that date. In some cases, it is possible that entry to {1} is free on that day, so you do not need to purchase a ticket. If you'd like to visit {2} on that day, please contact our Customer Service team to see if they can help.":
      function (a) {
        return [
          "If a particular date is shown as unavailable, this is either because ",
          a("0"),
          " is closed or because there are no more tickets to purchase for that date. In some cases, it is possible that entry to ",
          a("1"),
          " is free on that day, so you do not need to purchase a ticket. If you'd like to visit ",
          a("2"),
          " on that day, please contact our Customer Service team to see if they can help.",
        ];
      },
    "If a particular date is shown as unavailable, this is either because {venueName} is closed or because there are no more tickets to purchase for that date. In some cases, it is possible that entry to {venueName} is free on that day, so you do not need to purchase a ticket. If you'd like to visit {venueName} on that day, please contact our Customer Service team to see if they can help.":
      function (a) {
        return [
          "If a particular date is shown as unavailable, this is either because ",
          a("venueName"),
          " is closed or because there are no more tickets to purchase for that date. In some cases, it is possible that entry to ",
          a("venueName"),
          " is free on that day, so you do not need to purchase a ticket. If you'd like to visit ",
          a("venueName"),
          " on that day, please contact our Customer Service team to see if they can help.",
        ];
      },
    "If the tour has tickets included, their cost will not be refunded.":
      "If the tour has tickets included, their cost will not be refunded.",
    "If you have any questions, write to our contact center":
      "If you have any questions, write to our contact center",
    "Important info": "Important info",
    "Important information": "Important information",
    "In {productLang} language": function (a) {
      return ["In ", a("productLang"), " language"];
    },
    Includes: "Includes",
    "Incorrect code": "Incorrect code",
    "Incorrect login or password": "Incorrect login or password",
    "Independence from a guide or a group": "Independence from a guide or a group",
    Indonesian: "Indonesian",
    Instant: "Instant",
    "Instant booking confirmation": "Instant booking confirmation",
    "Instant confirmation": "Instant confirmation",
    "Instant confirmation. {0} You will receive a Self-Guided Audio Tour immediately after booking.":
      function (a) {
        return [
          "Instant confirmation. ",
          a("0"),
          " You will receive a Self-Guided Audio Tour immediately after booking.",
        ];
      },
    "Instant support": "Instant support",
    "Insufficient funds.": "Insufficient funds.",
    "Invalid email format": "Invalid email format",
    "Invalid or expired promo code. Please make sure it\u2019s correct or ask for support@wegotrip.com.":
      "Invalid or expired promo code. Please make sure it\u2019s correct or ask for support@wegotrip.com.",
    Invite: "Invite",
    "Is my payment secure?": "Is my payment secure?",
    "Is the date and time not right?{0}<0>Cancel booking</0>": function (a) {
      return ["Is the date and time not right?", a("0"), "<0>Cancel booking</0>"];
    },
    "It may take some time": "It may take some time",
    "It seems the are noting about \u201C{lastRequest}\u201D": function (a) {
      return ["It seems the are noting about \u201C", a("lastRequest"), "\u201D"];
    },
    "It seems, it may be longer to get tickets from the venue.":
      "It seems, it may be longer to get tickets from the venue.",
    Italian: "Italian",
    Itinerary: "Itinerary",
    "J.JONES": "J.JONES",
    Japanese: "Japanese",
    Jobs: "Jobs",
    Join: "Join",
    "Join 4500+ happy customers who use self-guided tours with entrance tickets":
      "Join 4500+ happy customers who use self-guided tours with entrance tickets",
    "Join WeGoTrip Affiliate Program": "Join WeGoTrip Affiliate Program",
    "Join other activities": "Join other activities",
    "Just share this link with your guests: <0>{0}<1/></0>": function (a) {
      return ["Just share this link with your guests: <0>", a("0"), "<1/></0>"];
    },
    "Just share this link with your guests: <0>{share_link}<1/></0>": function (a) {
      return ["Just share this link with your guests: <0>", a("share_link"), "<1/></0>"];
    },
    "Just two steps. In full confidence, without proof.":
      "Just two steps. In full confidence, without proof.",
    "Just two steps. No proof, only trust.": "Just two steps. No proof, only trust.",
    Korean: "Korean",
    Language: "Language",
    "Language: {0}": function (a) {
      return ["Language: ", a("0")];
    },
    "Language: {locale}": function (a) {
      return ["Language: ", a("locale")];
    },
    Languages: "Languages",
    "Last name": "Last name",
    Latvian: "Latvian",
    "Less than 1 hour": "Less than 1 hour",
    "Likely to recommend": "Likely to recommend",
    "Limit of payments for your card exceeded.": "Limit of payments for your card exceeded.",
    Link: "Link",
    "Link to": "Link to",
    Links: "Links",
    "Links&QR": "Links&QR",
    Lithuanian: "Lithuanian",
    "Lives in {0}": function (a) {
      return ["Lives in ", a("0")];
    },
    Loading: "Loading",
    "Loading payment services...": "Loading payment services...",
    "Loading reviews...": "Loading reviews...",
    "Loading time slots...": "Loading time slots...",
    "Loading timeslots...": "Loading timeslots...",
    "Loading...": "Loading...",
    "Log in": "Log in",
    "Log in to WeGoTrip": "Log in to WeGoTrip",
    "Log in to save favorite tours add see your orders from any device":
      "Log in to save favorite tours add see your orders from any device",
    "Log in with password": "Log in with password",
    Login: "Login",
    "Login to view your order": "Login to view your order",
    "Login \u2014 WeGoTrip": "Login \u2014 WeGoTrip",
    Logout: "Logout",
    "Looks like something is broken, please email us at support@wegotrip.com and we'll help you get your order.":
      "Looks like something is broken, please email us at support@wegotrip.com and we'll help you get your order.",
    "Looks like there are not enough tickets available for the selected timeslot. Please change the dates or number of participants, or email us at support@wegotrip.com and we'll help you complete your order.":
      "Looks like there are not enough tickets available for the selected timeslot. Please change the dates or number of participants, or email us at support@wegotrip.com and we'll help you complete your order.",
    "Looks like there is not already enought tickets for the selected timeslot Please change the dates or number of participants or email us at support@wegotrip.com and we'll help you get your order.":
      "Looks like there is not already enought tickets for the selected timeslot Please change the dates or number of participants or email us at support@wegotrip.com and we'll help you get your order.",
    "MM/YY": "MM/YY",
    MTS: "MTS",
    Materials: "Materials",
    Maximum: "Maximum",
    "Maximum {num} {participantsWord}, please remove some options": function (a) {
      return ["Maximum ", a("num"), " ", a("participantsWord"), ", please remove some options"];
    },
    "Maximum {num} {participants}, please remove some options": function (a) {
      return ["Maximum ", a("num"), " ", a("participants"), ", please remove some options"];
    },
    "Meet the host, {name}": function (a) {
      return ["Meet the host, ", a("name")];
    },
    Minimum: "Minimum",
    "Mobile App": "Mobile App",
    Monday: "Monday",
    "More about program": "More about program",
    "More details": "More details",
    "More details...": "More details...",
    "More login options": "More login options",
    "More popular activities": "More popular activities",
    "More than 1 day": "More than 1 day",
    "Most interesting cities in {0}": function (a) {
      return ["Most interesting cities in ", a("0")];
    },
    "Most recommended in {cityName} {0}": function (a) {
      return ["Most recommended in ", a("cityName"), " ", a("0")];
    },
    "Most recommended in {cityName} {categoryTitle}": function (a) {
      return ["Most recommended in ", a("cityName"), " ", a("categoryTitle")];
    },
    "Most recommended {cityName} {categoryTitle}": function (a) {
      return ["Most recommended ", a("cityName"), " ", a("categoryTitle")];
    },
    "Multimedia tours are filled with handy directions for museum, attraction or city walk along with stories from locals, pictures, audio guides, riddles and augmented reality \u2013 you will have a great time! With WeGoTrip app, you can explore and appreciate the best the city has to offer while always confident in knowing where you are, where you\u2019re off to, and why!":
      "Multimedia tours are filled with handy directions for museum, attraction or city walk along with stories from locals, pictures, audio guides, riddles and augmented reality \u2013 you will have a great time! With WeGoTrip app, you can explore and appreciate the best the city has to offer while always confident in knowing where you are, where you\u2019re off to, and why!",
    "Museums tours include entry e-tickets available offline":
      "Museums tours include entry e-tickets available offline",
    "My favorite moment while it Italy. You get to see a different side of Cinque Terre than just taking the train through the towns. Def book this experience. Its worth it!":
      "My favorite moment while it Italy. You get to see a different side of Cinque Terre than just taking the train through the towns. Def book this experience. Its worth it!",
    "My tours and tickets": "My tours and tickets",
    "Nearby cities": "Nearby cities",
    "Need some help?": "Need some help?",
    New: "New",
    Next: "Next",
    "Next step": "Next step",
    "No available dates were found. Please choose another tour.":
      "No available dates were found. Please choose another tour.",
    "No internet is required": "No internet is required",
    "No more things to do in {0}!": function (a) {
      return ["No more things to do in ", a("0"), "!"];
    },
    "No more things to do in {cityName}!": function (a) {
      return ["No more things to do in ", a("cityName"), "!"];
    },
    "No printing required, just use your smartphone! With the WeGoTrip app you can show your tickets on your phone even when offline.":
      "No printing required, just use your smartphone! With the WeGoTrip app you can show your tickets on your phone even when offline.",
    "No results": "No results",
    'No results"': 'No results"',
    "No timeslots found for given dates range. Please, change it or try again later.":
      "No timeslots found for given dates range. Please, change it or try again later.",
    "No tours presented here yet": "No tours presented here yet",
    Norwegian: "Norwegian",
    "Not likely to recommend": "Not likely to recommend",
    "Now download the tour in WeGoTrip app on your device":
      "Now download the tour in WeGoTrip app on your device",
    "Ok, got it": "Ok, got it",
    "On Demand tour": "On Demand tour",
    "On WeGoTrip since {year_since}": function (a) {
      return ["On WeGoTrip since ", a("year_since")];
    },
    "On demand tour in {0}": function (a) {
      return ["On demand tour in ", a("0")];
    },
    "On demand tour, {0}": function (a) {
      return ["On demand tour, ", a("0")];
    },
    "On demand tour, {0}, {1}": function (a) {
      return ["On demand tour, ", a("0"), ", ", a("1")];
    },
    "On-demand tours are generated with the help of AI with human post moderation. It allows us to propose personalized content for you while keeping good quality and low price":
      "On-demand tours are generated with the help of AI with human post moderation. It allows us to propose personalized content for you while keeping good quality and low price",
    "Once you book, you will receive confirmation email and SMS with the link to access to the tour {0} . {1}":
      function (a) {
        return [
          "Once you book, you will receive confirmation email and SMS with the link to access to the tour ",
          a("0"),
          ". ",
          a("1"),
        ];
      },
    "Once you book, you will receive confirmation email and SMS with the link to access to the tour {0}. {1}":
      function (a) {
        return [
          "Once you book, you will receive confirmation email and SMS with the link to access to the tour ",
          a("0"),
          ". ",
          a("1"),
        ];
      },
    "Once you book, you will receive confirmation email and SMS with the link to access to the tour{0}. {1}":
      function (a) {
        return [
          "Once you book, you will receive confirmation email and SMS with the link to access to the tour",
          a("0"),
          ". ",
          a("1"),
        ];
      },
    Ooops: "Ooops",
    "Oops! Sorry, there is no such page. Could we help you select tours for your next trip?":
      "Oops! Sorry, there is no such page. Could we help you select tours for your next trip?",
    "Oops, something went wrong.": "Oops, something went wrong.",
    "Oops, something went wrong.<0/>Please contact our <1>customer support</1>":
      "Oops, something went wrong.<0/>Please contact our <1>customer support</1>",
    "Open now:": "Open now:",
    "Order cancelled": "Order cancelled",
    "Order details": "Order details",
    "Order info": "Order info",
    "Order number": "Order number",
    "Order paid": "Order paid",
    "Order price": "Order price",
    "Order summary": "Order summary",
    "Organization issued your card is unavailable.":
      "Organization issued your card is unavailable.",
    Other: "Other",
    "Other login options": "Other login options",
    "Other things in {countryName}": function (a) {
      return ["Other things in ", a("countryName")];
    },
    "Other things to do in {0}": function (a) {
      return ["Other things to do in ", a("0")];
    },
    "Other things to do in {cityName}": function (a) {
      return ["Other things to do in ", a("cityName")];
    },
    "Overall rating": "Overall rating",
    "Overview of tourism activities in {cityName}, {0}": function (a) {
      return ["Overview of tourism activities in ", a("cityName"), ", ", a("0")];
    },
    "Overview of tourism activities in {cityName}, {countryName}": function (a) {
      return ["Overview of tourism activities in ", a("cityName"), ", ", a("countryName")];
    },
    Participants: "Participants",
    "Participants List": "Participants List",
    "Participants: {participantsCount}": function (a) {
      return ["Participants: ", a("participantsCount")];
    },
    Partnership: "Partnership",
    Password: "Password",
    Past: "Past",
    Pay: "Pay",
    "Pay <0/>": "Pay <0/>",
    "Pay with": "Pay with",
    Payment: "Payment",
    "Payment cancelled - unknown error": "Payment cancelled - unknown error",
    "Payment cancelled without reason. Please contact your card issuer.":
      "Payment cancelled without reason. Please contact your card issuer.",
    "Payment card": "Payment card",
    "Payment method": "Payment method",
    "Payment methods": "Payment methods",
    "Payment options": "Payment options",
    "Payment was not confirmed.": "Payment was not confirmed.",
    "Payment was successful": "Payment was successful",
    Payouts: "Payouts",
    People: "People",
    "People love WeGoTrip!": "People love WeGoTrip!",
    Personal: "Personal",
    "Personal details": "Personal details",
    "Personal info": "Personal info",
    "PersonalDetails.placeholderName": "John",
    "PersonalDetails.placeholderPhone": "+1 201 555 55 55",
    "PersonalDetails.placeholderSurname": "Doe",
    Phone: "Phone",
    "Phone:": "Phone:",
    "Place an order": "Place an order",
    "Places or a topic": "Places or a topic",
    "Places you will visit": "Places you will visit",
    "Placesor a topic": "Placesor a topic",
    "Please check the correctness of the entered email and try again":
      "Please check the correctness of the entered email and try again",
    "Please check the correctness of the entered number and try again":
      "Please check the correctness of the entered number and try again",
    "Please check your email and click the magic link to sign in.":
      "Please check your email and click the magic link to sign in.",
    "Please contact our": "Please contact our",
    "Please contact our <0>customer support</0>": "Please contact our <0>customer support</0>",
    "Please enter 4-digit code WeGoTrip just sent to {0}.": function (a) {
      return ["Please enter 4-digit code WeGoTrip just sent to ", a("0"), "."];
    },
    "Please enter the correct email": "Please enter the correct email",
    "Please enter traveler\u2019s full name": "Please enter traveler\u2019s full name",
    "Please enter valid email address": "Please enter valid email address",
    "Please enter valid traveler\u2019s phone": "Please enter valid traveler\u2019s phone",
    "Please enter your email": "Please enter your email",
    "Please enter your first name": "Please enter your first name",
    "Please enter your last name": "Please enter your last name",
    "Please enter your phone": "Please enter your phone",
    "Please enter {0} traveler name": function (a) {
      return ["Please enter ", a("0"), " traveler name"];
    },
    "Please enter {0} traveler phone": function (a) {
      return ["Please enter ", a("0"), " traveler phone"];
    },
    "Please enter {index} traveler name": function (a) {
      return ["Please enter ", a("index"), " traveler name"];
    },
    "Please enter {index} traveler phone": function (a) {
      return ["Please enter ", a("index"), " traveler phone"];
    },
    "Please fill in all fields": "Please fill in all fields",
    "Please fill in all forms before entering code":
      "Please fill in all forms before entering code",
    "Please select a date": "Please select a date",
    "Please select a time slot": "Please select a time slot",
    "Please select at least one category": "Please select at least one category",
    "Please try again": "Please try again",
    "Please, enter correct phone number": "Please, enter correct phone number",
    "Please, enter your login and password": "Please, enter your login and password",
    Polish: "Polish",
    Poor: "Poor",
    "Popular activities in {0}": function (a) {
      return ["Popular activities in ", a("0")];
    },
    "Popular cities to visit in {countryName}": function (a) {
      return ["Popular cities to visit in ", a("countryName")];
    },
    "Popular in {locationName}": function (a) {
      return ["Popular in ", a("locationName")];
    },
    "Popular links": "Popular links",
    "Popular on WeGoTrip": "Popular on WeGoTrip",
    "Popular worldwide": "Popular worldwide",
    Portuguese: "Portuguese",
    "Pre-order": "Pre-order",
    Preparing: "Preparing",
    Preview: "Preview",
    Price: "Price",
    "Price:": "Price:",
    "Price: High to Low": "Price: High to Low",
    "Price: Low to High": "Price: Low to High",
    "Privacy Policy": "Privacy Policy",
    "Privacy statement.": "Privacy statement.",
    "Probably not recommend": "Probably not recommend",
    "Proceed to payment": "Proceed to payment",
    "Processing your order": "Processing your order",
    "Product type": "Product type",
    Profile: "Profile",
    "Profitable together": "Profitable together",
    "Promo code": "Promo code",
    "Promo tools": "Promo tools",
    "Published by {0}": function (a) {
      return ["Published by ", a("0")];
    },
    "Published by {nickname}": function (a) {
      return ["Published by ", a("nickname")];
    },
    QR: "QR",
    Query: "Query",
    "Random order": "Random order",
    "Rate the tour": "Rate the tour",
    "Rate tour": "Rate tour",
    Rating: "Rating",
    "Read more": "Read more",
    "Receive email special offers, inspiration, tips, and other updates from WeGoTrip. I can unsubscribe at any time":
      "Receive email special offers, inspiration, tips, and other updates from WeGoTrip. I can unsubscribe at any time",
    "Recently viewed": "Recently viewed",
    "Recipient details": "Recipient details",
    Recommended: "Recommended",
    "Register to receive a promo code for a {element}. We will send you a promo code at the first login after registration.":
      function (a) {
        return [
          "Register to receive a promo code for a ",
          a("element"),
          ". We will send you a promo code at the first login after registration.",
        ];
      },
    Remove: "Remove",
    Required: "Required",
    "Reset dates": "Reset dates",
    Review: "Review",
    "Review your experience?": "Review your experience?",
    Reviews: "Reviews",
    "Reviews about activities in {cityName}": function (a) {
      return ["Reviews about activities in ", a("cityName")];
    },
    Romanian: "Romanian",
    "Route duration, h": "Route duration, h",
    "Route length: {0}": function (a) {
      return ["Route length: ", a("0")];
    },
    Russian: "Russian",
    "Russian rouble": "Russian rouble",
    "Safe and secure payment processing": "Safe and secure payment processing",
    "Safety during COVID-19": "Safety during COVID-19",
    Saturday: "Saturday",
    Save: "Save",
    "Save QR": "Save QR",
    "Save your favorites. Quickly access bookings. Find self-guided tours and tickets worldwide":
      "Save your favorites. Quickly access bookings. Find self-guided tours and tickets worldwide",
    Saved: "Saved",
    "Scan the Download code to get offline access to your tickets":
      "Scan the Download code to get offline access to your tickets",
    "Scan the QR code to access your activity in the WeGoTrip app":
      "Scan the QR code to access your activity in the WeGoTrip app",
    "Scan the QR code to get offline access to your tickets":
      "Scan the QR code to get offline access to your tickets",
    "Scan the QR code to get offline access to your tickets in the app for offline access":
      "Scan the QR code to get offline access to your tickets in the app for offline access",
    "Scan the QR code with your smartphone's camera to quickly download the tour":
      "Scan the QR code with your smartphone's camera to quickly download the tour",
    Search: "Search",
    "See all": "See all",
    "See all {0} tours & tickets": function (a) {
      return ["See all ", a("0"), " tours & tickets"];
    },
    "See all {variantToursAndTickets}": function (a) {
      return ["See all ", a("variantToursAndTickets")];
    },
    "See less": "See less",
    "See more": "See more",
    "See what other places are available for excursions":
      "See what other places are available for excursions",
    "See what other places are available for the tour":
      "See what other places are available for the tour",
    Select: "Select",
    "Select a date": "Select a date",
    "Select a product from the list or visit our page on":
      "Select a product from the list or visit our page on",
    "Select at least one category": "Select at least one category",
    "Select category": "Select category",
    "Select currency": "Select currency",
    "Select date": "Select date",
    "Select language": "Select language",
    "Select participants": "Select participants",
    "Select payment method": "Select payment method",
    "Select specific tours": "Select specific tours",
    "Select time": "Select time",
    "Select tours for your next trip": "Select tours for your next trip",
    "Select your categories": "Select your categories",
    "Self guided audio tour": "Self guided audio tour",
    "Self-guided audio tour": "Self-guided audio tour",
    "Self-guided audio tours on mobile app": "Self-guided audio tours on mobile app",
    "Self-guided audio tours on the mobile app": "Self-guided audio tours on the mobile app",
    "Self-guided tours": "Self-guided tours",
    Send: "Send",
    "Send Feedback": "Send Feedback",
    "Send link to me": "Send link to me",
    "Send magic link": "Send magic link",
    "Send us your full name and order date by email: support@wegotrip.com or in chat.":
      "Send us your full name and order date by email: support@wegotrip.com or in chat.",
    "Send us your full name and order date by email: {supportEmail} or in chat.": function (a) {
      return [
        "Send us your full name and order date by email: ",
        a("supportEmail"),
        " or in chat.",
      ];
    },
    "Sending payment": "Sending payment",
    "Sending...": "Sending...",
    Settings: "Settings",
    Share: "Share",
    'Show "Show more" button': 'Show "Show more" button',
    "Show all dates": "Show all dates",
    "Show all reviews": "Show all reviews",
    "Show author's answer": "Show author's answer",
    "Show desktop": "Show desktop",
    "Show in store\u2192": "Show in store\u2192",
    "Show less": "Show less",
    "Show less...": "Show less...",
    "Show mobile": "Show mobile",
    "Show more": "Show more",
    "Show more cities": "Show more cities",
    "Show more...": "Show more...",
    "Show order in my account": "Show order in my account",
    "Show other dates": "Show other dates",
    "Show results": "Show results",
    "Show results: {prefetchedCount}": function (a) {
      return ["Show results: ", a("prefetchedCount")];
    },
    "Show ticket in new window": "Show ticket in new window",
    "Show ticket in the app": "Show ticket in the app",
    "Sign In to save favorite tours add see your orders from any device":
      "Sign In to save favorite tours add see your orders from any device",
    "Sign Up": "Sign Up",
    "Sign in": "Sign in",
    "Sign in in WeGoTrip Account": "Sign in in WeGoTrip Account",
    "Sign in with Email": "Sign in with Email",
    "Sign in with email": "Sign in with email",
    "Sign in with password": "Sign in with password",
    "Sign in with {social}": function (a) {
      return ["Sign in with ", a("social")];
    },
    "Sign out": "Sign out",
    "Sign up": "Sign up",
    "Sign up for WeGoTrip Account": "Sign up for WeGoTrip Account",
    "Sign up in WeGoTrip": "Sign up in WeGoTrip",
    "Similar experience for you": "Similar experience for you",
    "Skip the lines to the best attractions": "Skip the lines to the best attractions",
    "Skip-the-line": "Skip-the-line",
    "Skip-the-line ticket": "Skip-the-line ticket",
    Slovak: "Slovak",
    Slovenian: "Slovenian",
    "Sold out": "Sold out",
    "Something went wrong during signing in process via email. Perhaps, your link has expired.":
      "Something went wrong during signing in process via email. Perhaps, your link has expired.",
    "Something went wrong.": "Something went wrong.",
    "Something went wrong. Please update your reservation":
      "Something went wrong. Please update your reservation",
    "Sort by": "Sort by",
    "Sort by{0}": function (a) {
      return ["Sort by", a("0")];
    },
    Spanish: "Spanish",
    "Speaks {0}": function (a) {
      return ["Speaks ", a("0")];
    },
    "Specify dates": "Specify dates",
    "Specify places you want to include or a topic of the tour":
      "Specify places you want to include or a topic of the tour",
    "Start a live chat": "Start a live chat",
    "Start live chat": "Start live chat",
    "Start the tour at any time after purchase": "Start the tour at any time after purchase",
    "Start the tour at any time after purchase.": "Start the tour at any time after purchase.",
    "Starting point": "Starting point",
    Statistics: "Statistics",
    "Stay flexible": "Stay flexible",
    "Subscription successful!": "Subscription successful!",
    Subtotal: "Subtotal",
    "Successfully sent": "Successfully sent",
    Sunday: "Sunday",
    Swedish: "Swedish",
    "TOP attractions in ${0}": function (a) {
      return ["TOP attractions in $", a("0")];
    },
    "TOP attractions in {0}": function (a) {
      return ["TOP attractions in ", a("0")];
    },
    "Tell us what you do, who is your main audience, its size and geo. Attach links to presentations or screenshots. To take a screenshot and get a link we recommend monosnap.com, to upload a PDF file \u2013 sendspace.com":
      "Tell us what you do, who is your main audience, its size and geo. Attach links to presentations or screenshots. To take a screenshot and get a link we recommend monosnap.com, to upload a PDF file \u2013 sendspace.com",
    "Temporarily unavailable": "Temporarily unavailable",
    "Terms and Conditions": "Terms and Conditions",
    Terrible: "Terrible",
    "Thank you for booking!": "Thank you for booking!",
    "Thank you! Booking was successful": "Thank you! Booking was successful",
    "Thanks for your feedback!": "Thanks for your feedback!",
    "The Best  {0} in {cityName} (Updated {year})": function (a) {
      return ["The Best  ", a("0"), " in ", a("cityName"), " (Updated ", a("year"), ")"];
    },
    "The Best Things To Do in {cityName} (Updated {year})": function (a) {
      return ["The Best Things To Do in ", a("cityName"), " (Updated ", a("year"), ")"];
    },
    "The Best Things To Do in {cityName}: audio tours, excursions and activities in {year}. Find the top-rated trips in {cityName} with entrance tickets and prices":
      function (a) {
        return [
          "The Best Things To Do in ",
          a("cityName"),
          ": audio tours, excursions and activities in ",
          a("year"),
          ". Find the top-rated trips in ",
          a("cityName"),
          " with entrance tickets and prices",
        ];
      },
    "The Best {0} in {cityName} (Updated {year})": function (a) {
      return ["The Best ", a("0"), " in ", a("cityName"), " (Updated ", a("year"), ")"];
    },
    "The Best {cityName} Self-Guided Audio Tours in {year}. Travel at your own rules with online audio guides to the sights and places of interest of {cityName}":
      function (a) {
        return [
          "The Best ",
          a("cityName"),
          " Self-Guided Audio Tours in ",
          a("year"),
          ". Travel at your own rules with online audio guides to the sights and places of interest of ",
          a("cityName"),
        ];
      },
    "The Best {cityName} Things To Do in {year}: Tours, Audio Guides, Attractions & Activities":
      function (a) {
        return [
          "The Best ",
          a("cityName"),
          " Things To Do in ",
          a("year"),
          ": Tours, Audio Guides, Attractions & Activities",
        ];
      },
    "The CVC number is incorrect. Please try again.":
      "The CVC number is incorrect. Please try again.",
    "The PIN entered is incorrect. Please try again.":
      "The PIN entered is incorrect. Please try again.",
    "The allowable number of PIN tries was exceeded. Please try another card.":
      "The allowable number of PIN tries was exceeded. Please try another card.",
    "The balance credit limit has been exceeded available on the card. Please try another card or contact your bank.":
      "The balance credit limit has been exceeded available on the card. Please try another card or contact your bank.",
    "The best audio guides for the {0} from experts. Download and listen to online audio tours of the {0}. {1}":
      function (a) {
        return [
          "The best audio guides for the ",
          a("0"),
          " from experts. Download and listen to online audio tours of the ",
          a("0"),
          ".",
        ];
      },
    "The best audio guides for the {0} from experts. Download and listen to online audio tours of the {1}. {2}":
      function (a) {
        return [
          "The best audio guides for the ",
          a("0"),
          " from experts. Download and listen to online audio tours of the ",
          a("1"),
          ".",
        ];
      },
    "The best audio guides for {0} from experts. Download and listen to online audio guides to the sights and places of interest of {1}.":
      function (a) {
        return [
          "The best audio guides for ",
          a("0"),
          " from experts. Download and listen to online audio guides to the sights and places of interest of ",
          a("1"),
          ".",
        ];
      },
    "The best audio guides for {attractionName} from experts WeGoTrip \u2606 Prices from {0} \u2606 Download and listen to online audio tours of {attractionName}.":
      function (a) {
        return [
          "The best audio guides for ",
          a("attractionName"),
          " from experts WeGoTrip \u2606 Prices from ",
          a("0"),
          " \u2606 Download and listen to online audio tours of ",
          a("attractionName"),
          ".",
        ];
      },
    "The best audio guides for {attractionName} from experts. Download and listen to online audio tours of {attractionName}. {0}":
      function (a) {
        return [
          "The best audio guides for ",
          a("attractionName"),
          " from experts. Download and listen to online audio tours of ",
          a("attractionName"),
          ".",
        ];
      },
    "The best audio guides for {cityName} from experts. Download and listen to online audio guides to the sights and places of interest of {0}.":
      function (a) {
        return [
          "The best audio guides for ",
          a("cityName"),
          " from experts. Download and listen to online audio guides to the sights and places of interest of ",
          a("0"),
          ".",
        ];
      },
    "The card does not support the payment currency. Please try another card or contact your bank.":
      "The card does not support the payment currency. Please try another card or contact your bank.",
    "The card does not support this type of purchase. Please try another card or contact your bank.":
      "The card does not support this type of purchase. Please try another card or contact your bank.",
    "The card number is incorrect. Please try again.":
      "The card number is incorrect. Please try again.",
    "The card was declined because it requires a PIN. Please try again.":
      "The card was declined because it requires a PIN. Please try again.",
    "The card was declined for an unknown reason. Please contact your bank.":
      "The card was declined for an unknown reason. Please contact your bank.",
    "The card was declined for an unknown reason. Please try another card or contact your bank.":
      "The card was declined for an unknown reason. Please try another card or contact your bank.",
    "The card was declined for an unknown reason. Please, contact your bank.":
      "The card was declined for an unknown reason. Please, contact your bank.",
    "The card was declined. Please contact your bank or try another card.":
      "The card was declined. Please contact your bank or try another card.",
    "The card was declined. Please try again later or contact your bank.":
      "The card was declined. Please try again later or contact your bank.",
    "The card was declined. Please, try another card or contact your bank.":
      "The card was declined. Please, try another card or contact your bank.",
    "The card, or account the card is connected to, is invalid. Please try another card or contact your bank.":
      "The card, or account the card is connected to, is invalid. Please try another card or contact your bank.",
    "The discount code was not found or is no longer active":
      "The discount code was not found or is no longer active",
    "The entered CVC is incorrect. Please check the entered data.":
      "The entered CVC is incorrect. Please check the entered data.",
    "The entered CVC is incorrect. Please try again.":
      "The entered CVC is incorrect. Please try again.",
    "The entered expiry month is incorrect. Please check the entered data.":
      "The entered expiry month is incorrect. Please check the entered data.",
    "The entered expiry month is incorrect. Please try again.":
      "The entered expiry month is incorrect. Please try again.",
    "The entered expiry year is incorrect. Please check the entered data.":
      "The entered expiry year is incorrect. Please check the entered data.",
    "The entered expiry year is incorrect. Please try again.":
      "The entered expiry year is incorrect. Please try again.",
    "The first impression of the tour by {name}": function (a) {
      return ["The first impression of the tour by ", a("name")];
    },
    "The host's activities": "The host's activities",
    "The host\u2019s activities": "The host\u2019s activities",
    "The most interesting attractions in {0}": function (a) {
      return ["The most interesting attractions in ", a("0")];
    },
    "The most interesting tours in {0}": function (a) {
      return ["The most interesting tours in ", a("0")];
    },
    "The most interesting tours in {cityNameNotInclined}": function (a) {
      return ["The most interesting tours in ", a("cityNameNotInclined")];
    },
    "The most interesting tours in {cityName}": function (a) {
      return ["The most interesting tours in ", a("cityName")];
    },
    "The museum has a day off": "The museum has a day off",
    "The payment amount exceeds the amount that\u2019s allowed. Please try again or contact your bank.":
      "The payment amount exceeds the amount that\u2019s allowed. Please try again or contact your bank.",
    "The payment couldn\u2019t be processed. Please try again or contact your bank.":
      "The payment couldn\u2019t be processed. Please try again or contact your bank.",
    "The payment isn\u2019t permitted. Please try another card or contact your bank.":
      "The payment isn\u2019t permitted. Please try another card or contact your bank.",
    "The payment period has expired": "The payment period has expired",
    "The postal code is incorrect. Please try again.":
      "The postal code is incorrect. Please try again.",
    "The price includes": "The price includes",
    "The red marker is the starting point of the tour. The tour route goes within the circle":
      "The red marker is the starting point of the tour. The tour route goes within the circle",
    "The refund amount for the self-guided tour": "The refund amount for the self-guided tour",
    "The route of the tour goes within circle": "The route of the tour goes within circle",
    "The subscription has already been issued.": "The subscription has already been issued.",
    "The tour and tickets for this participant will be available on your phone in the WeGoTrip app.":
      "The tour and tickets for this participant will be available on your phone in the WeGoTrip app.",
    "The {0}: Audio guide - Download and listen to online audio tours": function (a) {
      return ["The ", a("0"), ": Audio guide - Download and listen to online audio tours"];
    },
    "There are no dates available. Change the number of attendees to attend or select another product.":
      "There are no dates available. Change the number of attendees to attend or select another product.",
    "There are no tickets for the selected date. Reduce the number of participants":
      "There are no tickets for the selected date. Reduce the number of participants",
    "These tours and tickets were either purchased with the same email used in your WeGoTrip account or when you were signed in":
      "These tours and tickets were either purchased with the same email used in your WeGoTrip account or when you were signed in",
    "Things to do in {0}": function (a) {
      return ["Things to do in ", a("0")];
    },
    "Things to do in {cityName}": function (a) {
      return ["Things to do in ", a("cityName")];
    },
    "This activity is Non-refundable": "This activity is Non-refundable",
    "This activity is non-refantable": "This activity is non-refantable",
    "This card has expired. Pleas try another payment mehtod.":
      "This card has expired. Pleas try another payment mehtod.",
    "This card has expired. Please try another payment method.":
      "This card has expired. Please try another payment method.",
    "This card was lost and blocked by the issuer.":
      "This card was lost and blocked by the issuer.",
    "This card was stolen and blocked by the issuer":
      "This card was stolen and blocked by the issuer",
    "This field cannot be empty": "This field cannot be empty",
    "This field is required": "This field is required",
    "This is free and users can choose the appropriate date":
      "This is free and users can choose the appropriate date",
    "This payment was declined due to being considered fradulent.":
      "This payment was declined due to being considered fradulent.",
    "This tour includes {0} {1} {2}. You can find detailed information under the section 'What's included' on this page.":
      function (a) {
        return [
          "This tour includes ",
          a("0"),
          " ",
          a("1"),
          " ",
          a("2"),
          ". You can find detailed information under the section 'What's included' on this page.",
        ];
      },
    "This tour is available at any time": "This tour is available at any time",
    "This tour was generated with the help of AI with human post moderation. It allows us to propose more diverse content for you while keeping good quality and lower price":
      "This tour was generated with the help of AI with human post moderation. It allows us to propose more diverse content for you while keeping good quality and lower price",
    "This website collects cookies to deliver the best user experience":
      "This website collects cookies to deliver the best user experience",
    Thursday: "Thursday",
    Ticket: "Ticket",
    "Ticket - available for download": "Ticket - available for download",
    "Ticket - being prepared": "Ticket - being prepared",
    "Ticket - is being prepared, will be available no later than":
      "Ticket - is being prepared, will be available no later than",
    "Ticket - reservations need to be updated": "Ticket - reservations need to be updated",
    "Ticket is being prepared, will be available no later than":
      "Ticket is being prepared, will be available no later than",
    "Ticket price": "Ticket price",
    "Ticket sales": "Ticket sales",
    Tickets: "Tickets",
    "Tickets are sold out for this category, please select another category or product.":
      "Tickets are sold out for this category, please select another category or product.",
    "Tickets in {0}": function (a) {
      return ["Tickets in ", a("0")];
    },
    "Tickets included": "Tickets included",
    "Tickets left nowhere": "Tickets left nowhere",
    "Tickets provided by": "Tickets provided by",
    "Tickets to the {0}": function (a) {
      return ["Tickets to the ", a("0")];
    },
    "Tickets to top attractions": "Tickets to top attractions",
    "Tickets to top attractions in {0}": function (a) {
      return ["Tickets to top attractions in ", a("0")];
    },
    Time: "Time",
    "Time: {time}": function (a) {
      return ["Time: ", a("time")];
    },
    "Tired waiting in lines to attractions? Don't wait for the guide to start the tour. The tour and ticket are always at hand.":
      "Tired waiting in lines to attractions? Don't wait for the guide to start the tour. The tour and ticket are always at hand.",
    Title: "Title",
    "Too much emails": "Too much emails",
    "Top attractions": "Top attractions",
    "Top attractions in {0}": function (a) {
      return ["Top attractions in ", a("0")];
    },
    "Top attractions in {cityName}": function (a) {
      return ["Top attractions in ", a("cityName")];
    },
    "Top attractions in {countryName}": function (a) {
      return ["Top attractions in ", a("countryName")];
    },
    "Top categories": "Top categories",
    "Top cities": "Top cities",
    "Top destinations": "Top destinations",
    "Top-rated activities": "Top-rated activities",
    "Total:": "Total:",
    Tour: "Tour",
    "Tour and ticket on your mobile app": "Tour and ticket on your mobile app",
    "Tour preview": "Tour preview",
    "Tours and tickets in {locationName}": function (a) {
      return ["Tours and tickets in ", a("locationName")];
    },
    "Tours and tickets in {name}": function (a) {
      return ["Tours and tickets in ", a("name")];
    },
    "Tours are loading": "Tours are loading",
    "Tours in your language": "Tours in your language",
    "Tours in {0}": function (a) {
      return ["Tours in ", a("0")];
    },
    "Tours in {cityName}": function (a) {
      return ["Tours in ", a("cityName")];
    },
    "To\xA0order": "To\xA0order",
    "Travel independently and safely": "Travel independently and safely",
    Traveler: "Traveler",
    "Traveler {0}": function (a) {
      return ["Traveler ", a("0")];
    },
    "Travelers Interests": "Travelers Interests",
    "Travelers Interests in {cityName}": function (a) {
      return ["Travelers Interests in ", a("cityName")];
    },
    "Try again": "Try again",
    "Try different email": "Try different email",
    "Try it": "Try it",
    Tuesday: "Tuesday",
    Turkish: "Turkish",
    URL: "URL",
    "URL of your website, social page, etc": "URL of your website, social page, etc",
    "US Dollar": "US Dollar",
    "US customers": "US customers",
    Ukrainian: "Ukrainian",
    Unavailable: "Unavailable",
    Undecided: "Undecided",
    "Unexpected error occurred during order process.":
      "Unexpected error occurred during order process.",
    "Unexpected error occurred during payment process.":
      "Unexpected error occurred during payment process.",
    "Up to": "Up to",
    Upcoming: "Upcoming",
    Update: "Update",
    "Update reservation": "Update reservation",
    "Updating your discount...": "Updating your discount...",
    "Urban Legends \u2014 MTS and WeGoTrip": "Urban Legends \u2014 MTS and WeGoTrip",
    "Urban legends": "Urban legends",
    "User{randomUserId}": function (a) {
      return ["User", a("randomUserId")];
    },
    "View details": "View details",
    "View return policy": "View return policy",
    "View the refund terms.": "View the refund terms.",
    "Visit Help Center": "Visit Help Center",
    Waiting: "Waiting",
    "Walking Tour": "Walking Tour",
    "Want to discover all there is to do in {0}": function (a) {
      return ["Want to discover all there is to do in ", a("0")];
    },
    "Want to discover all there is to do in {cityName}": function (a) {
      return ["Want to discover all there is to do in ", a("cityName")];
    },
    "We also sent you a link to download the tour in {0} {1} {2}.": function (a) {
      return [
        "We also sent you a link to download the tour in ",
        a("0"),
        " ",
        a("1"),
        " ",
        a("2"),
        ".",
      ];
    },
    "We are always here to help. In case you need anything \u2014 write us in chat, email or phone us.":
      "We are always here to help. In case you need anything \u2014 write us in chat, email or phone us.",
    "We are available in English. No endless holds. If you have any questions, please, contact us!":
      "We are available in English. No endless holds. If you have any questions, please, contact us!",
    "We are very sorry that you did not like our emails. Please write the reason, what exactly was wrong?":
      "We are very sorry that you did not like our emails. Please write the reason, what exactly was wrong?",
    "We are waiting for an addition on availability":
      "We are waiting for an addition on availability",
    "We sent an SMS with a link to download the tour/ticket to other participants. Now you can download your tour in the WeGoTrip app.":
      "We sent an SMS with a link to download the tour/ticket to other participants. Now you can download your tour in the WeGoTrip app.",
    "We support popular payment methods used across the globe, notably Visa, Mastercard, Apple Pay and Google Pay.":
      "We support popular payment methods used across the globe, notably Visa, Mastercard, Apple Pay and Google Pay.",
    "We use cookies on our website to make it better. By continuing to use our website, you agree to the use of cookies.":
      "We use cookies on our website to make it better. By continuing to use our website, you agree to the use of cookies.",
    "We use cookies on our website to make our website even better. By continuing to use the site, you agree to the use of cookies.":
      "We use cookies on our website to make our website even better. By continuing to use the site, you agree to the use of cookies.",
    "We will not send you any more emails!": "We will not send you any more emails!",
    "We will refund you the cost of the audio tour within 3 business days.":
      "We will refund you the cost of the audio tour within 3 business days.",
    "We will refund you the cost of the audio tour within {days}.": function (a) {
      return ["We will refund you the cost of the audio tour within ", a("days"), "."];
    },
    "We will send the tour within 48 hours or refund your money":
      "We will send the tour within 48 hours or refund your money",
    "We will send them a message with link to start the tour on their devices.":
      "We will send them a message with link to start the tour on their devices.",
    "We will send tickets and a link to start the tour to <0>{0}</0>": function (a) {
      return ["We will send tickets and a link to start the tour to <0>", a("0"), "</0>"];
    },
    "We will send tickets and a link to start the tour to: <0>{0}</0>": function (a) {
      return ["We will send tickets and a link to start the tour to: <0>", a("0"), "</0>"];
    },
    "We will use this phone number to send a link to download tour via SMS and in case of order problems.":
      "We will use this phone number to send a link to download tour via SMS and in case of order problems.",
    "We're looking for your tours": "We're looking for your tours",
    "We're preparing your tickets": "We're preparing your tickets",
    "We've sent a verification link to <0>{email}</0>": function (a) {
      return ["We've sent a verification link to <0>", a("email"), "</0>"];
    },
    "WeGoTrip Affiliate ID": "WeGoTrip Affiliate ID",
    "WeGoTrip Blog": "WeGoTrip Blog",
    "WeGoTrip ID": "WeGoTrip ID",
    "WeGoTrip Studio": "WeGoTrip Studio",
    "WeGoTrip is": "WeGoTrip is",
    "WeGoTrip \u2014 Audio Tours & Tourist Audio Guides around the world":
      "WeGoTrip \u2014 Audio Tours & Tourist Audio Guides around the world",
    "WeGoTrip \u2014 Audio tours and tickets in one mobile app":
      "WeGoTrip \u2014 Audio tours and tickets in one mobile app",
    "WeGoTrip \u2014 Find high-quality audio guides with tickets to attractions from experts for self-travel around the world.":
      "WeGoTrip \u2014 Find high-quality audio guides with tickets to attractions from experts for self-travel around the world.",
    Website: "Website",
    Wednesday: "Wednesday",
    "What do this tour includes?": "What do this tour includes?",
    "What do this tour {0} include?": function (a) {
      return ["What do this tour ", a("0"), " include?"];
    },
    "What does the price include?": "What does the price include?",
    "What else to see in {cityName}": function (a) {
      return ["What else to see in ", a("cityName")];
    },
    "What is WeGoTrip": "What is WeGoTrip",
    "What is WeGoTrip?": "What is WeGoTrip?",
    "What payment methods does WeGoTrip support?": "What payment methods does WeGoTrip support?",
    "What people say about {0}": function (a) {
      return ["What people say about ", a("0")];
    },
    "What will happen after purchase?": "What will happen after purchase?",
    "What will you discover next?": "What will you discover next?",
    "What you need to prepare for the activity": "What you need to prepare for the activity",
    "What you will get": "What you will get",
    "What's included": "What's included",
    WhatsApp: "WhatsApp",
    "What\u2019s included": "What\u2019s included",
    "What\u2019s inside the tour, how it differs from regular audio guides?":
      "What\u2019s inside the tour, how it differs from regular audio guides?",
    "What\u2019s not included": "What\u2019s not included",
    "When:": "When:",
    "When: {formattedDate}": function (a) {
      return ["When: ", a("formattedDate")];
    },
    "When: {formattedDate} at {formattedTime}": function (a) {
      return ["When: ", a("formattedDate"), " at ", a("formattedTime")];
    },
    "When: {formattedDate}, at {formattedTime}": function (a) {
      return ["When: ", a("formattedDate"), ", at ", a("formattedTime")];
    },
    "Where will you go next?": "Where will you go next?",
    "Who will get a link to {0} {1} {2}": function (a) {
      return ["Who will get a link to ", a("0"), " ", a("1"), " ", a("2")];
    },
    "Who will get a link to {0}<0><1/><2>Checkout is fast and secure</2></0>{1} {2}": function (a) {
      return [
        "Who will get a link to ",
        a("0"),
        "<0><1/><2>Checkout is fast and secure</2></0>",
        a("1"),
        " ",
        a("2"),
      ];
    },
    "Why can't I choose my preferred date in the calendar?":
      "Why can't I choose my preferred date in the calendar?",
    Widgets: "Widgets",
    "With Tickets": "With Tickets",
    "With WeGoTrip from {year_since}": function (a) {
      return ["With WeGoTrip from ", a("year_since")];
    },
    "With the in-app audio guide, you decide when to start, pause, and finish your tour":
      "With the in-app audio guide, you decide when to start, pause, and finish your tour",
    "With the in-app audio guide, you decide when to start, pause, and finish your tour.":
      "With the in-app audio guide, you decide when to start, pause, and finish your tour.",
    "Without Tickets": "Without Tickets",
    "Working hours": "Working hours",
    "Write a public review": "Write a public review",
    "Write us about your question": "Write us about your question",
    "Yes! This tour {0} an access to audio tour. To allow everyone in your group to download the audio tour to their devices select appropriate numbers of people.":
      function (a) {
        return [
          "Yes! This tour ",
          a("0"),
          " an access to audio tour. To allow everyone in your group to download the audio tour to their devices select appropriate numbers of people.",
        ];
      },
    "Yes, our online payment system is secure. In fact it encrypts your payment information to protect you against fraud and unauthorized transactions. Your credit card will be charged once you book. However, if the operator of attraction does not accept your booking, the amount will be credited to the original account it was taken from.":
      "Yes, our online payment system is secure. In fact it encrypts your payment information to protect you against fraud and unauthorized transactions. Your credit card will be charged once you book. However, if the operator of attraction does not accept your booking, the amount will be credited to the original account it was taken from.",
    "Yes, the timeslot allows you to enter the venue without having to wait in the long queues.":
      "Yes, the timeslot allows you to enter the venue without having to wait in the long queues.",
    "Yookassa failed to process payment.": "Yookassa failed to process payment.",
    "You can always proceed without signing in. If you're signing in for the first time, we'll create an account for you.":
      "You can always proceed without signing in. If you're signing in for the first time, we'll create an account for you.",
    "You can get tickets offline in the app. Get bonuses and offers from the mobile app":
      "You can get tickets offline in the app. Get bonuses and offers from the mobile app",
    "You could only select {0} with {categories}": function (a) {
      return ["You could only select ", a("0"), " with ", a("categories")];
    },
    "You didn't add any products yet": "You didn't add any products yet",
    "You didn't booked anything yet": "You didn't booked anything yet",
    "You don't need to you remember a password. Enter your email address and we'll send you a magic link. If you have already purchased tickets, please use the same email address.":
      "You don't need to you remember a password. Enter your email address and we'll send you a magic link. If you have already purchased tickets, please use the same email address.",
    "You don\u2019t have any tours and tickets yet":
      "You don\u2019t have any tours and tickets yet",
    "You don\u2019t need Internet to access the tour and and tickets after downloading":
      "You don\u2019t need Internet to access the tour and and tickets after downloading",
    "You don\u2019t need Internet to access the tour and and tickets after downloading.":
      "You don\u2019t need Internet to access the tour and and tickets after downloading.",
    "You have listened to at least a third of the tour.":
      "You have listened to at least a third of the tour.",
    "You have listened to at least {third} of the tour.": function (a) {
      return ["You have listened to at least ", a("third"), " of the tour."];
    },
    "You have placed an order for {travelers} participants. Invite other participants by entering their details below. We will send them a link to download the tour.":
      function (a) {
        return [
          "You have placed an order for ",
          a("travelers"),
          " participants. Invite other participants by entering their details below. We will send them a link to download the tour.",
        ];
      },
    "You have successfully signed up": "You have successfully signed up",
    "You may also like": "You may also like",
    "You receive tour and tickets right after the booking.":
      "You receive tour and tickets right after the booking.",
    "You receive tour and tickets right after the booking. Your plans won\u2019t be disrupted.":
      "You receive tour and tickets right after the booking. Your plans won\u2019t be disrupted.",
    "You will need to install WeGoTrip mobile app to use audio tour, it's easy, just tap a link from confirmation SMS.":
      "You will need to install WeGoTrip mobile app to use audio tour, it's easy, just tap a link from confirmation SMS.",
    "You will need to install WeGoTrip mobile app to use audio tour, it\u2019s easy, just tap a link from confirmation SMS.":
      "You will need to install WeGoTrip mobile app to use audio tour, it\u2019s easy, just tap a link from confirmation SMS.",
    "You will receive a Self-Guided Audio Tour immediately after booking":
      "You will receive a Self-Guided Audio Tour immediately after booking",
    "You will receive a Self-Guided Audio Tour with a ticket immediately after booking":
      "You will receive a Self-Guided Audio Tour with a ticket immediately after booking",
    "You will receive a tour with a ticket immediately after ordering":
      "You will receive a tour with a ticket immediately after ordering",
    "You will receive your order by the selected date":
      "You will receive your order by the selected date",
    "You'll receive a self-guided audio tour right after booking":
      "You'll receive a self-guided audio tour right after booking",
    "You'll receive a self-guided audio tour right after booking.":
      "You'll receive a self-guided audio tour right after booking.",
    "You'll receive tour and tickets right after the booking":
      "You'll receive tour and tickets right after the booking",
    "You'll receive tour right after the booking": "You'll receive tour right after the booking",
    "You've successfully booked tour & tickets.": "You've successfully booked tour & tickets.",
    "Your audio tour is available in the app": "Your audio tour is available in the app",
    "Your audio tour is paid and available in the app":
      "Your audio tour is paid and available in the app",
    "Your booking number: {code}": function (a) {
      return ["Your booking number: ", a("code")];
    },
    "Your bookings": "Your bookings",
    "Your card has insufficient funds.": "Your card has insufficient funds.",
    "Your card has insufficient funds. Please, try another card or contact your bank.":
      "Your card has insufficient funds. Please, try another card or contact your bank.",
    "Your card number is incorrect. Please check the entered data.":
      "Your card number is incorrect. Please check the entered data.",
    "Your card number is incorrect. Please try again.":
      "Your card number is incorrect. Please try again.",
    "Your card was declined.": "Your card was declined.",
    "Your card was declined. Please check the entered data":
      "Your card was declined. Please check the entered data",
    "Your card was declined. Your request was in test mode, but used a non test card. For a list of valid test cards, visit: https://stripe.com/docs/testing.":
      "Your card was declined. Your request was in test mode, but used a non test card. For a list of valid test cards, visit: https://stripe.com/docs/testing.",
    "Your card was declined. Your request was in test mode, but used a non-test card. For a list of valid test cards, visit: https://stripe.com/docs/testing.":
      "Your card was declined. Your request was in test mode, but used a non-test card. For a list of valid test cards, visit: https://stripe.com/docs/testing.",
    "Your email": "Your email",
    "Your favorites": "Your favorites",
    "Your free {0} sightseeing self-guided audio tour is waiting.": function (a) {
      return ["Your free ", a("0"), " sightseeing self-guided audio tour is waiting."];
    },
    "Your generated Link and QR": "Your generated Link and QR",
    "Your order has been paid, and tickets are now in preparation. You can download the audio tour in the app":
      "Your order has been paid, and tickets are now in preparation. You can download the audio tour in the app",
    "Your phone": "Your phone",
    "Your ticket is being prepared!<0/>We'll send you new ticket via email or sms":
      "Your ticket is being prepared!<0/>We'll send you new ticket via email or sms",
    "Your ticket is ready. Find it in the app or in the email we sent you":
      "Your ticket is ready. Find it in the app or in the email we sent you",
    "Your wishlist is empty": "Your wishlist is empty",
    "a third": "a third",
    "abra cadabra {0}": function (a) {
      return ["abra cadabra ", a("0")];
    },
    "after purchase": "after purchase",
    "an SMS to ${phone}": function (a) {
      return ["an SMS to $", a("phone")];
    },
    "an SMS to {phone}": function (a) {
      return ["an SMS to ", a("phone")];
    },
    "an email to your email address ${email}": function (a) {
      return ["an email to your email address $", a("email")];
    },
    "an email to your email address {email}": function (a) {
      return ["an email to your email address ", a("email")];
    },
    "an interactive audio tour": "an interactive audio tour",
    "an interactive audio tour in the WeGoTrip app":
      "an interactive audio tour in the WeGoTrip app",
    and: "and",
    "and tickets": "and tickets",
    "and you consent to the processing of personal data.":
      "and you consent to the processing of personal data.",
    "and {0} more": function (a) {
      return ["and ", a("0"), " more"];
    },
    at: "at",
    "check the conditions": "check the conditions",
    closed: "closed",
    "customer support": "customer support",
    "customerservice@wegotrip.com": "customerservice@wegotrip.com",
    "e-mail": "e-mail",
    "e.g. Catacombs of Paris: audio tour": "e.g. Catacombs of Paris: audio tour",
    "e.g. Paris or Versailles": "e.g. Paris or Versailles",
    "email@example.com": "email@example.com",
    "entrance to the venue and": "entrance to the venue and",
    "entry ticket": "entry ticket",
    "for person": "for person",
    from: "from",
    link: "link",
    "of your required activity {productType}": function (a) {
      return ["of your required activity ", a("productType")];
    },
    or: "or",
    "per person": "per person",
    "per tour": "per tour",
    "plus a small booking fee to cover the cost of payment processing and customer support.":
      "plus a small booking fee to cover the cost of payment processing and customer support.",
    popular: "popular",
    "rating & review": "rating & review",
    "ratings & review": "ratings & review",
    "ratings & reviews": "ratings & reviews",
    review: "review",
    reviews: "reviews",
    "sold out": "sold out",
    "support@wegotrip.com": "support@wegotrip.com",
    "terms and conditions": "terms and conditions",
    "the tour": "the tour",
    ticket: "ticket",
    tickets: "tickets",
    "tickets to the {0}": function (a) {
      return ["tickets to the ", a("0")];
    },
    to: "to",
    today: "today",
    total: "total",
    "tour goes withing circle": "tours goes within cirlce",
    waiting: "waiting",
    weekend: "weekend",
    "with tickets": "with tickets",
    "your discount {discount}.": function (a) {
      return ["your discount ", a("discount"), "."];
    },
    "{0, plural, =0 {# tours & tickets} one {# tour & ticket} few {# tours & tickets} many {# tours & tickets} other {# tours & tickets}}":
      function (a) {
        return [
          a("0", "plural", {
            0: ["#", " tours & tickets"],
            one: ["#", " tour & ticket"],
            few: ["#", " tours & tickets"],
            many: ["#", " tours & tickets"],
            other: ["#", " tours & tickets"],
          }),
        ];
      },
    "{0, plural, =0 {# tours and tickets} one {# tour} few {# tours and tickets} many {# tours and tickets} other {# tours and tickets}}":
      function (a) {
        return [
          a("0", "plural", {
            0: ["#", " tours and tickets"],
            one: ["#", " tour"],
            few: ["#", " tours and tickets"],
            many: ["#", " tours and tickets"],
            other: ["#", " tours and tickets"],
          }),
        ];
      },
    "{0, plural, =0 {# tours and tickets} one {# tour} few {# tours and tickets} other {# tours and tickets}}":
      function (a) {
        return [
          a("0", "plural", {
            0: ["#", " tours and tickets"],
            one: ["#", " tour"],
            few: ["#", " tours and tickets"],
            other: ["#", " tours and tickets"],
          }),
        ];
      },
    "{0, plural, =0 {No participants} one {# participant} few {# participants} many {# participants} other {# participants}}":
      function (a) {
        return [
          a("0", "plural", {
            0: "No participants",
            one: ["#", " participant"],
            few: ["#", " participants"],
            many: ["#", " participants"],
            other: ["#", " participants"],
          }),
        ];
      },
    "{0, plural, =0 {} one {# rating} few {# ratings} many {# ratings} other {# ratings}}":
      function (a) {
        return [
          a("0", "plural", {
            0: "",
            one: ["#", " rating"],
            few: ["#", " ratings"],
            many: ["#", " ratings"],
            other: ["#", " ratings"],
          }),
        ];
      },
    "{0, plural, =0 {} one {# review} few {# reviews} many {# reviews} other {# reviews}}":
      function (a) {
        return [
          a("0", "plural", {
            0: "",
            one: ["#", " review"],
            few: ["#", " reviews"],
            many: ["#", " reviews"],
            other: ["#", " reviews"],
          }),
        ];
      },
    "{0, plural, =0 {} one {Show 1 more} few {Show # more} many {Show # more} other {Show # more}}":
      function (a) {
        return [
          a("0", "plural", {
            0: "",
            one: "Show 1 more",
            few: ["Show ", "#", " more"],
            many: ["Show ", "#", " more"],
            other: ["Show ", "#", " more"],
          }),
        ];
      },
    "{0, plural, =0 {} one {Show 1 more} other {Show all # photos}}": function (a) {
      return [
        a("0", "plural", { 0: "", one: "Show 1 more", other: ["Show all ", "#", " photos"] }),
      ];
    },
    "{0}": function (a) {
      return [a("0")];
    },
    "{0} Audio Tours": function (a) {
      return [a("0"), " Audio Tours"];
    },
    "{0} book in {cityName} for {1} {2} | WeGoTrip": function (a) {
      return [a("0"), " book in ", a("cityName"), " for ", a("1"), " ", a("2"), " | WeGoTrip"];
    },
    "{0} in {locationName}": function (a) {
      return [a("0"), " in ", a("locationName")];
    },
    "{0} in {locationName} | WeGoTrip": function (a) {
      return [a("0"), " in ", a("locationName"), " | WeGoTrip"];
    },
    "{0} options available": function (a) {
      return [a("0"), " options available"];
    },
    "{0} ratings & reviews": function (a) {
      return [a("0"), " ratings & reviews"];
    },
    "{0} tours": function (a) {
      return [a("0"), " tours"];
    },
    "{0} tours and tickets": function (a) {
      return [a("0"), " tours and tickets"];
    },
    "{0} | WeGoTrip": function (a) {
      return [a("0"), " | WeGoTrip"];
    },
    "{0}, {1} - Buy tickets Online": function (a) {
      return [a("0"), ", ", a("1"), " - Buy tickets Online"];
    },
    "{ErrorText}": function (a) {
      return [a("ErrorText")];
    },
    "{accordionSubtitle}": function (a) {
      return [a("accordionSubtitle")];
    },
    "{accordionTitle}": function (a) {
      return [a("accordionTitle")];
    },
    "{afterPurchaseMessage}": function (a) {
      return [a("afterPurchaseMessage")];
    },
    "{amount, plural, =0 {Show all reviews} one {Show # review} few {Show # reviews} many {Show all # reviews} other {Show all # reviews}}":
      function (a) {
        return [
          a("amount", "plural", {
            0: "Show all reviews",
            one: ["Show ", "#", " review"],
            few: ["Show ", "#", " reviews"],
            many: ["Show all ", "#", " reviews"],
            other: ["Show all ", "#", " reviews"],
          }),
        ];
      },
    "{attractionName} Audio Guides: Download and listen to online audio tours": function (a) {
      return [a("attractionName"), " Audio Guides: Download and listen to online audio tours"];
    },
    "{attractionName} Tickets: Book Online": function (a) {
      return [a("attractionName"), " Tickets: Book Online"];
    },
    "{attractionName}, {locationName} - Buy tickets Online": function (a) {
      return [a("attractionName"), ", ", a("locationName"), " - Buy tickets Online"];
    },
    "{attractionName}: Audio guide - Download and listen to online audio tours": function (a) {
      return [a("attractionName"), ": Audio guide - Download and listen to online audio tours"];
    },
    "{attractionName}: Audio guides - Download and listen to online audio tours": function (a) {
      return [a("attractionName"), ": Audio guides - Download and listen to online audio tours"];
    },
    "{category}": function (a) {
      return [a("category")];
    },
    "{cityName} Audio Tours": function (a) {
      return [a("cityName"), " Audio Tours"];
    },
    "{cityName} Self-Guided {0} in {year}. Audio Guides with included entrance sights tickets to the best places of {cityName}":
      function (a) {
        return [
          a("cityName"),
          " Self-Guided ",
          a("0"),
          " in ",
          a("year"),
          ". Audio Guides with included entrance sights tickets to the best places of ",
          a("cityName"),
        ];
      },
    "{city}: {text} Tours": function (a) {
      return [a("city"), ": ", a("text"), " Tours"];
    },
    "{count, plural, =0 {} one {# review} few {# reviews} many {# reviews} other {# reviews}}":
      function (a) {
        return [
          a("count", "plural", {
            0: "",
            one: ["#", " review"],
            few: ["#", " reviews"],
            many: ["#", " reviews"],
            other: ["#", " reviews"],
          }),
        ];
      },
    "{count, plural, =0 {} one {# review} few {# reviews} many {# reviews} other {# reviews}} for audio tours by {0}":
      function (a) {
        return [
          a("count", "plural", {
            0: "",
            one: ["#", " review"],
            few: ["#", " reviews"],
            many: ["#", " reviews"],
            other: ["#", " reviews"],
          }),
          " for audio tours by ",
          a("0"),
        ];
      },
    "{count, plural, =0 {} one {# review} few {# reviews} many {# reviews} other {# reviews}} for audio tours in {cityName}":
      function (a) {
        return [
          a("count", "plural", {
            0: "",
            one: ["#", " review"],
            few: ["#", " reviews"],
            many: ["#", " reviews"],
            other: ["#", " reviews"],
          }),
          " for audio tours in ",
          a("cityName"),
        ];
      },
    "{count, plural, =0 {} one {# review} many {# reviews} other {# reviews}} for audio tours in {0}":
      function (a) {
        return [
          a("count", "plural", {
            0: "",
            one: ["#", " review"],
            many: ["#", " reviews"],
            other: ["#", " reviews"],
          }),
          " for audio tours in ",
          a("0"),
        ];
      },
    "{count, plural, =0 {} one {# review} other {# reviews}}": function (a) {
      return [a("count", "plural", { 0: "", one: ["#", " review"], other: ["#", " reviews"] })];
    },
    "{count, plural, =0 {} one {# review} other {# reviews}} for audio tours in {0}": function (a) {
      return [
        a("count", "plural", { 0: "", one: ["#", " review"], other: ["#", " reviews"] }),
        " for audio tours in ",
        a("0"),
      ];
    },
    "{date}": function (a) {
      return [a("date")];
    },
    "{discount} discount activated": function (a) {
      return [a("discount"), " discount activated"];
    },
    "{duration} hours": function (a) {
      return [a("duration"), " hours"];
    },
    "{duration} {0}": function (a) {
      return [a("duration"), " ", a("0")];
    },
    "{error}": function (a) {
      return [a("error")];
    },
    "{fromString} - {toString}": function (a) {
      return [a("fromString"), " to ", a("toString")];
    },
    "{itemsCount, plural, =0 {# guides} one {# guide} few {# guides} many {# guides} other {# guides}}":
      function (a) {
        return [
          a("itemsCount", "plural", {
            0: ["#", " guides"],
            one: ["#", " guide"],
            few: ["#", " guides"],
            many: ["#", " guides"],
            other: ["#", " guides"],
          }),
        ];
      },
    "{itemsCount, plural, =0 {# tours and tickets} one {# tour} few {# tours and tickets} many {# tours and tickets} other {# tours and tickets}}":
      function (a) {
        return [
          a("itemsCount", "plural", {
            0: ["#", " tours and tickets"],
            one: ["#", " tour"],
            few: ["#", " tours and tickets"],
            many: ["#", " tours and tickets"],
            other: ["#", " tours and tickets"],
          }),
        ];
      },
    "{itemsCount, plural, =0 {# tours and tickets} one {# tour} few {# tours and tickets} other {# tours and tickets}}":
      function (a) {
        return [
          a("itemsCount", "plural", {
            0: ["#", " tours and tickets"],
            one: ["#", " tour"],
            few: ["#", " tours and tickets"],
            other: ["#", " tours and tickets"],
          }),
        ];
      },
    "{itemsCount, plural, =0 {# tours} one {# tour} few {# tours and tickets} many {# tours and tickets} other {# tours and tickets}}":
      function (a) {
        return [
          a("itemsCount", "plural", {
            0: ["#", " tours"],
            one: ["#", " tour"],
            few: ["#", " tours and tickets"],
            many: ["#", " tours and tickets"],
            other: ["#", " tours and tickets"],
          }),
        ];
      },
    "{num, plural, one {# participant} few {# participants} many {# participants} other {# participants}}":
      function (a) {
        return [
          a("num", "plural", {
            one: ["#", " participant"],
            few: ["#", " participants"],
            many: ["#", " participants"],
            other: ["#", " participants"],
          }),
        ];
      },
    "{pageTitle}": function (a) {
      return [a("pageTitle")];
    },
    "{participantsCount, plural, =0 {No participants} one {# participant} few {# participants} many {# participants} other {# participants}}":
      function (a) {
        return [
          a("participantsCount", "plural", {
            0: "No participants",
            one: ["#", " participant"],
            few: ["#", " participants"],
            many: ["#", " participants"],
            other: ["#", " participants"],
          }),
        ];
      },
    "{products_count, plural, =0 {# activities} one {# activity} few {# activities} many {# activities} other {# activities}}":
      function (a) {
        return [
          a("products_count", "plural", {
            0: ["#", " activities"],
            one: ["#", " activity"],
            few: ["#", " activities"],
            many: ["#", " activities"],
            other: ["#", " activities"],
          }),
        ];
      },
    "{reviewsAmount} Reviews": function (a) {
      return [a("reviewsAmount"), " Reviews"];
    },
    "{reviewsAmount} ratings & reviews": function (a) {
      return [a("reviewsAmount"), " ratings & reviews"];
    },
    "{roundedFrom, plural, one {#} other {#}}": function (a) {
      return [a("roundedFrom", "plural", { one: ["#"], other: ["#"] })];
    },
    "{sameProfileWordForLingui}": function (a) {
      return [a("sameProfileWordForLingui")];
    },
    "{text}": function (a) {
      return [a("text")];
    },
    "{ticketText}": function (a) {
      return [a("ticketText")];
    },
    "{title}": function (a) {
      return [a("title")];
    },
    "{travelersCount, plural, =0 {} one {1 person} other {# people}}": function (a) {
      return [a("travelersCount", "plural", { 0: "", one: "1 person", other: ["#", " people"] })];
    },
    "{travelersCount, plural, =0 {} one {1 traveler} other {# travelers}}": function (a) {
      return [
        a("travelersCount", "plural", { 0: "", one: "1 traveler", other: ["#", " travelers"] }),
      ];
    },
    "{value, plural, one {a day} other {# days}}": function (a) {
      return [a("value", "plural", { one: "a day", other: ["#", " days"] })];
    },
    "{value, plural, one {a minute} other {# minutes}}": function (a) {
      return [a("value", "plural", { one: "a minute", other: ["#", " minutes"] })];
    },
    "{value, plural, one {an hour} other {# hours}}": function (a) {
      return [a("value", "plural", { one: "an hour", other: ["#", " hours"] })];
    },
    "\u0421heck availability": "\u0421heck availability",
    "\u0427\u0430\u0441\u0442\u043E \u043F\u043E\u043A\u0443\u043F\u0430\u044E\u0442 \u0432\u043C\u0435\u0441\u0442\u0435":
      "\u0427\u0430\u0441\u0442\u043E \u043F\u043E\u043A\u0443\u043F\u0430\u044E\u0442 \u0432\u043C\u0435\u0441\u0442\u0435",
    "\u0447\u0430\u0441": "\u0447\u0430\u0441",
    "\u0447\u0430\u0441\u0430": "\u0447\u0430\u0441\u0430",
    "\u0447\u0430\u0441\u043E\u0432": "\u0447\u0430\u0441\u043E\u0432",
  },
};
