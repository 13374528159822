export const icons = [
  "logo/gray",
  "bag",
  "pencil",
  "product",
  "exit",
  "lang/de",
  "lang/en",
  "lang/es",
  "lang/fr",
  "lang/it",
  "lang/nl",
  "lang/pl",
  "lang/ru",
  "surfing",
  "categories",
  "mobile",
  "sort",
  "charge",
  "line",
  "cookies",
  "mail-subscriptionDone",
  "mail-subscription",
  "filter",
  "event",
  "planet",
  "building",
  "done",
  "done/all",
  "refresh",
  "loading",
  "head",
  "cloud_download",
  "download",
  "product-type",
  "shopping-cart",
  "details",
  "chevron/up",
  "chevron/down",
  "chevron/left",
  "chevron/right",
  "group",
  "flag",
  "audio-ticket",
  "bookmark",
  "bookmark/multiple",
  "house",
  "reviews/main",
  "mobile-screen-share",
  "computer",
  "star/yellow",
  "star/grey",
  "star/green",
  "star/accent",
  "star/accentBorder",
  "star/white",
  "star/whiteBorder",
  "step/complete",
  "step/complete-bold",
  "step/delayed",
  "step/delayed-bold",
  "car",
  "clock",
  "copy",
  "copy/light",
  "check/accent",
  "check/green",
  "check/white",
  "check/black",
  "check/circled-accent",
  "cross/gray",
  "cross/red",
  "face/sad",
  "facebook",
  "twitter",
  "instagram",
  "pinterest",
  "tiktok",
  "linkedin",
  "close/white",
  "skip-line",
  "headphones",
  "headphones/gray",
  "headphones/mic",
  "hand",
  "price",
  "picture",
  "booking-number",
  "calendar",
  "list",
  "clock",
  "globe-language",
  "hand-phone",
  "control-check",
  "martian",
  "found-flag",
  "language",
  "participiants",
  "mail",
  "language-content",
  "language-content/hieroglyph",
  "location",
  "lock",
  "key",
  "pause",
  "play",
  "pin",
  "pin/finish",
  "pin/arrow",
  "pin/outlined",
  "pay/apple",
  "pay/appleBlack",
  "pay/google",
  "pay/googleBlack",
  "pay/card",
  "route",
  "search",
  "preorder",
  "aitour",
  "search/thin",
  "chat",
  "tick",
  "arrow/left",
  "arrow/right",
  "person",
  "person/add",
  "euro",
  "dollar",
  "pound",
  "help",
  "heart",
  "heart/white",
  "heart/red-filled",
  "heart/red-filled-bordered",
  "heart/gray",
  "share",
  "shield/check",
  "shield/empty",
  "link",
  "email",
  "ticket",
  "ticket/gray",
  "timer",
  "tiqets",
  "loginBenefits",
  "whatsapp",
  "wifi/no",
  "newline",
  "vk",
  "notFound",
];
