export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";
export const UPDATE_BY_PREFETCHED = "UPDATE_BY_PREFETCHED";
export const FETCH_MOST_RECOMMENDED_SUCCESS = "FETCH_MOST_RECOMMENDED_SUCCESS";
export const FETCH_MOST_RECOMMENDED_FAILURE = "FETCH_MOST_RECOMMENDED_FAILURE";
export const FETCH_MOST_RECOMMENDED = "FETCH_MOST_RECOMMENDED";

export const SET_PRODUCTS_FILTERS = "SET_PRODUCTS_FILTERS";
export const RESET_GENERAL_FILTERS = "RESET_GENERAL_FILTERS";
export const RESET_PRICE_FILTERS = "RESET_PRICE_FILTERS";
export const RESET_DATE_FILTERS = "RESET_DATE_FILTERS";
export const RESET_PRODUCTS_FILTERS = "RESET_PRODUCTS_FILTERS";
export const SET_SORT_ORDER = "SET_SORT_ORDER";

export const FETCH_SELECTED_PRODUCT = "FETCH_SELECTED_PRODUCT";
export const FETCH_SELECTED_PRODUCT_FAILURE = "FETCH_SELECTED_PRODUCT_FAILURE";
export const FETCH_SELECTED_PRODUCT_SUCCESS = "FETCH_SELECTED_PRODUCT_SUCCESS";

export const FETCH_REVIEWS = "FETCH_REVIEWS";
export const FETCH_REVIEWS_FAILURE = "FETCH_REVIEWS_FAILURE";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const NORMALIZE_REVIEWS = "NORMALIZE_REVIEWS";
export const NORMALIZE_REVIEWS_SUCCESS = "NORMALIZE_REVIEWS_SUCCESS";

export const FETCH_AUTHOR_REVIEWS = "FETCH_AUTHOR_REVIEWS";
export const FETCH_AUTHOR_REVIEWS_FAILURE = "FETCH_AUTHOR_REVIEWS_FAILURE";
export const FETCH_AUTHOR_REVIEWS_SUCCESS = "FETCH_AUTHOR_REVIEWS_SUCCESS";

export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const FETCH_SIMILAR_PRODUCTS = "FETCH_SIMILAR_PRODUCTS";
export const GET_SIMILAR_PRODUCTS_SUCCESS = "GET_SIMILAR_PRODUCTS_SUCCESS";
export const GET_SIMILAR_PRODUCTS_FAILURE = "GET_SIMILAR_PRODUCTS_FAILURE";

export const FETCH_UPSALE_PRODUCTS = "FETCH_UPSALE_PRODUCTS";
export const GET_UPSALE_PRODUCTS_SUCCESS = "GET_UPSALE_PRODUCTS_SUCCESS";
export const GET_UPSALE_PRODUCTS_FAILURE = "GET_UPSALE_PRODUCTS_FAILURE";

export const FETCH_ATTRACTIONS_REQUEST = "FETCH_ATTRACTIONS_REQUEST";
export const FETCH_ATTRACTIONS_SUCCESS = "FETCH_ATTRACTIONS_SUCCESS";
export const FETCH_ATTRACTIONS_FAILURE = "FETCH_ATTRACTIONS_FAILURE";

export const FETCH_CITY = "FETCH_CITY";
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAILURE = "FETCH_CITY_FAILURE";

export const FETCH_CITIES = "FETCH_CITIES";
export const FETCH_CITIES_SUCCESS = "FETCH_RECOMMENDED_CITIES_SUCCESS";
export const FETCH_CITIES_FAILURE = "FETCH_RECOMMENDED_CITIES_FAILURE";

export const LOGIN = "LOGIN";
export const LOGIN_EMAIL = "LOGIN_EMAIL";
export const LOGIN_SMS = "LOGIN_SMS";
export const LOGIN_MAGIC_CODE = "LOGIN_MAGIC_CODE";
export const LOGIN_SMS_VERIFY = "LOGIN_SMS_VERIFY";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const RESET_USER_LOADING = "RESET_USER_LOADING";

export const LOGIN_POPUP_OPEN = "LOGIN_POPUP_OPEN";
export const LOGIN_POPUP_CLOSE = "LOGIN_POPUP_CLOSE";

export const FETCH_LANGUAGES = "FETCH_LANGUAGES";
export const FETCH_LANGUAGES_FAILURE = "FETCH_LANGUAGES_FAILURE";
export const FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS";

export const FETCH_CURRENCIES = "FETCH_CURRENCIES";
export const FETCH_CURRENCIES_FAILURE = "FETCH_CURRENCIES_FAILURE";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";

export const FETCH_DEFAULT_CURRENCY = "FETCH_DEFAULT_CURRENCY";
export const FETCH_DEFAULT_CURRENCY_FAILURE = "FETCH_DEFAULT_CURRENCY_FAILURE";
export const FETCH_DEFAULT_CURRENCY_SUCCESS = "FETCH_DEFAULT_CURRENCY_SUCCESS";
export const SET_DEFAULT_CURRENCY = "SET_DEFAULT_CURRENCY";

export const FETCH_DAYS = "FETCH_DAYS";
export const FETCH_DAYS_FAILURE = "FETCH_DAYS_FAILURE";
export const FETCH_DAYS_SUCCESS = "FETCH_DAYS_SUCCESS";
export const ENABLE_CALENDAR_NEXT_MONTH = "ENABLE_CALENDAR_NEXT_MONTH";

export const FETCH_TIMESLOTS = "FETCH_TIMESLOTS";
export const FETCH_TIMESLOTS_FAILURE = "FETCH_TIMESLOTS_FAILURE";
export const FETCH_TIMESLOTS_SUCCESS = "FETCH_TIMESLOTS_SUCCESS";

export const RESET_AVAILABILITY = "RESET_AVAILABILITY";
export const SET_CALCULATED_PRICE = "SET_CALCULATED_PRICE";
export const SET_FROM_ONDEMAND_FORM = "SET_FROM_ONDEMAND_FORM";
export const SET_ORIGINAL_PASSENGER_COUNT = "SET_ORIGINAL_PASSENGER_COUNT";
export const SAVE_CUSTOMER_DATA = "SAVE_CUSTOMER_DATA";

export const FETCH_OPTIONS = "FETCH_OPTIONS";
export const FETCH_OPTIONS_FAILURE = "FETCH_OPTIONS_FAILURE";
export const FETCH_OPTIONS_SUCCESS = "FETCH_OPTIONS_SUCCESS";

export const CHECK_DISCOUNT = "CHECK_DISCOUNT";
export const CHECK_DISCOUNT_INIT = "CHECK_DISCOUNT_INIT";
export const CHECK_DISCOUNT_SUCCESS = "CHECK_DISCOUNT_SUCCESS";
export const CHECK_DISCOUNT_FAILURE = "CHECK_DISCOUNT_FAILURE";
export const RESET_DISCOUNT = "RESET_DISCOUNT";

export const CREATE_TOUR_REQUEST = "CREATE_TOUR_REQUEST";
export const CREATE_TOUR_SUCCESS = "CREATE_TOUR_SUCCESS";
export const CREATE_TOUR_FAILURE = "CREATE_TOUR_FAILURE";

export const SET_FORM_DATA = "SET_FORM_DATA";

export const BOMB_YOOKASSA = "BOMB_YOOKASSA";
export const GET_EXTERNAL_PAYMENT_STATUS = "GET_EXTERNAL_PAYMENT_STATUS";

export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const GET_PAYMENT_STATUS_SUCCESS = "GET_PAYMENT_STATUS_SUCCESS";
export const GET_PAYMENT_STATUS_FAILURE = "GET_PAYMENT_STATUS_FAILURE";

export const SELECT_PAYMENT_METHOD = "SELECT_PAYMENT_METHOD";
export const SELECT_PAYMENT_METHOD_SUCCESS = "SELECT_PAYMENT_METHOD_SUCCESS";
export const SELECT_PAYMENT_METHOD_FAILURE = "SELECT_PAYMENT_METHOD_FAILURE";

export const FILL_PAYMENT = "FILL_PAYMENT";
export const PAYMENT_STATUS_TIMEOUT = "PAYMENT_STATUS_TIMEOUT";

export const SET_CLIENT_SECRET = "SET_CLIENT_SECRET";
export const SUBMIT_STRIPE_PAYMENT = "SUBMIT_STRIPE_PAYMENT";
export const SUBMIT_STRIPE_PAYMENT_FAILURE = "SUBMIT_STRIPE_PAYMENT_FAILURE";
export const SUBMIT_STRIPE_PAYMENT_SUCCESS = "SUBMIT_STRIPE_PAYMENT_SUCCESS";

export const ERROR_ADD = "ERROR_ADD";
export const ERROR_RESOLVE = "ERROR_RESOLVE";

export const DISABLE_PAYMENT_FORM = "DISABLE_PAYMENT_FORM";
export const ENABLE_PAYMENT_FORM = "ENABLE_PAYMENT_FORM";

export const DISABLE_PAYMENT_SUBMITTING = "DISABLE_PAYMENT_SUBMITTING";
export const ENABLE_PAYMENT_SUBMITTING = "ENABLE_PAYMENT_SUBMITTING";

export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const CREATE_PAYMENT_FAILURE = "CREATE_PAYMENT_FAILURE";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";

export const ADD_USER_FAVOURITE = "ADD_USER_FAVOURITE";
export const ADD_USER_FAVOURITE_FAILURE = "ADD_USER_FAVOURITE_FAILURE";
export const ADD_USER_FAVOURITE_SUCCESS = "ADD_USER_FAVOURITE_SUCCESS";

export const REMOVE_USER_FAVOURITE = "REMOVE_USER_FAVOURITE";
export const REMOVE_USER_FAVOURITE_FAILURE = "REMOVE_USER_FAVOURITE_FAILURE";
export const REMOVE_USER_FAVOURITE_SUCCESS = "REMOVE_USER_FAVOURITE_SUCCESS";

export const SHOW_ALERT = "SHOW_ALERT";
export const SET_ALERT_CONTENT = "SET_ALERT_CONTENT";
export const SET_ALERT_VISIBILITY = "SET_ALERT_VISIBILITY";
export const HIDE_ALERT = "HIDE_ALERT";

export const FETCH_AFFILIATE_PARTNER = "FETCH_AFFILIATE_PARTNER";
export const FETCH_AFFILIATE_PARTNER_SUCCESS = "FETCH_AFFILIATE_PARTNER_SUCCESS";
export const FETCH_AFFILIATE_PARTNER_FAILURE = "FETCH_AFFILIATE_PARTNER_FAILURE";

export const CREATE_AFFILIATE_PARTNER = "CREATE_AFFILIATE_PARTNER";
export const CREATE_AFFILIATE_PARTNER_SUCCESS = "CREATE_AFFILIATE_PARTNER_SUCCESS";
export const CREATE_AFFILIATE_PARTNER_FAILURE = "CREATE_AFFILIATE_PARTNER_FAILURE";

export const UPDATE_AFFILIATE_PARTNER = "UPDATE_AFFILIATE_PARTNER";
export const UPDATE_AFFILIATE_PARTNER_SUCCESS = "UPDATE_AFFILIATE_PARTNER_SUCCESS";
export const UPDATE_AFFILIATE_PARTNER_FAILURE = "UPDATE_AFFILIATE_PARTNER_FAILURE";

export const CREATE_UNSUBSCRIBE = "CREATE_UNSUBSCRIBE";
export const CREATE_UNSUBSCRIBE_SUCCESS = "CREATE_UNSUBSCRIBE_SUCCESS";
export const CREATE_UNSUBSCRIBE_FAILURE = "CREATE_UNSUBSCRIBE_FAILURE";

export const SEND_UNSUBSCRIBE_TEXT = "SEND_UNSUBSCRIBE_TEXT";
export const SEND_UNSUBSCRIBE_TEXT_SUCCESS = "SEND_UNSUBSCRIBE_SUCCESS";
export const SEND_UNSUBSCRIBE_TEXT_FAILURE = "SEND_UNSUBSCRIBE_FAILURE";

export const SET_OVERVIEW = "SET_OVERVIEW";

export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SAVE_DATE_SELECT = "SAVE_DATE_SELECT";
export const SAVE_CATEGORIES_SELECT = "SAVE_CATEGORIES_SELECT";
export const SAVE_TIME_SELECT = "SAVE_TIME_SELECT";

export const SET_BOOKING_TOTAL = "SET_BOOKING_TOTAL";

export const FETCH_USER_BOOKINGS = "FETCH_USER_BOOKINGS";
export const FETCH_USER_BOOKINGS_SUCCESS = "FETCH_USER_BOOKINGS_SUCCESS";
export const FETCH_USER_BOOKINGS_FAILURE = "FETCH_USER_BOOKINGS_FAILURE";

export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";
export const SEND_FEEDBACK_FAILURE = "SEND_FEEDBACK_FAILURE";
